import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { ILensInfonAction, initialState } from "./initialState";
// import { getLensInfo } from "@/src/models/lens/lensServer";
export const useLensInfoStore = create<ILensInfonAction>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                // getLens: async (id: string) => {
                //     const res = await getLensInfo(id);
                //     set({ isLoading: false, data: initDate(res), error: "" });
                // },
                favoriteProduct() {
                    const { is_favorite } = get().data;
                    set({
                        data: {
                            ...get().data,
                            is_favorite: !is_favorite,
                        },
                    });
                },
                changesetlens(conditions: Record<string, any>) {
                    set({
                        datavalue: conditions,
                    });
                },
                changeColorImg(color_canvas_img: string) {
                    set({ color_canvas_img });
                },
                changetype(types) {
                    set({ glassestype: types });
                },
                setLensOptions(data) {
                    set({
                        data: {
                            ...get().data,
                            lensOptions: data,
                        },
                    });
                },
            }),
            {
                name: "lensinfo-storage",
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
        {
            name: "lensinfo-store",
        },
    ),
);

function initDate(productInfo: any) {
    if (!productInfo?.product) {
        return productInfo;
    }
    const { product, frame_picture, frame_image, frame_image_total } =
        productInfo;
    let info = {
        frame_image_total: frame_image_total,
        frame_image: frame_image || frame_picture,
        cate: product.master_categories_id,
        HaveClips: product.have_clips != 0,
        rimless: product.products_frame === "Rimless",
        isRless: product.products_frame === "Rimless",
        isSpotsFromDanYang:
            !product.outside_processing_channel &&
            product.master_categories_id == 3,
        isSpotsColorFromDanYang:
            !product.outside_processing_channel &&
            product.master_categories_id == 3 &&
            product.replaceable_lens === 1,
        isColorLess: ["F19304R", "F19303R", "F19302R"].includes(
            product.products_model,
        ),
        isHidePolarized: ["BJ6602"].includes(product.products_model),
        isSemi: product.products_frame === "Semi-Rimless",
        isFrameHeightLessThan40: product.dimensions_height < 40,
        isFrameHeightLessThan30: product.dimensions_height < 30,
        isFolding: product.master_categories_id === 11,
        isNeckglasses: product.master_categories_id === 12,
        is_kids:
            product.products_sex === 3 && product.master_categories_id !== 6,
    };

    return {
        ...productInfo,
        ...info,
    };
}
