import { IStyleProps } from "@/ui-component/interface";
import dynamic from "next/dynamic";
import { ReactNode } from "react";
import { LoadingProps } from "./FmToast";

export interface IToastProps extends IStyleProps, LoadingProps {
    children?: ReactNode;
    visible: boolean;
    type?: "info" | "loading";
    seconds?: number; // 为负数时则表示不使用该seconds
    onChange?: (visible: boolean) => void; // 告诉用户该toast是否存在
}

const FmToast = dynamic(() => import("./FmToast"), {
    ssr: false,
});
export default FmToast;
