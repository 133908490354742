import { HttpMethods } from "../configUtils";
import {
    formatHTTPGetUrl,
    getPreUrlByApiTag,
} from "./replaceUrlBydiffrentFetch";

/**
 *
 * java接口相关的http请求封装
 *
 * url：路径
 * params：参数
 *
 */

async function handleErrors(response: Response): Promise<any> {
    if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.code === "succes") {
        return responseData.data;
    } else {
        throw new Error(responseData.msg);
    }
}

class HandleJavaPacyFetch {
    static async request(
        url: string,
        method: HttpMethods,
        params: { [key: string]: any } = {},
        options: RequestInit = {},
    ): Promise<any> {
        let req_url = getPreUrlByApiTag(url, "api/javaPacy/");

        options.method = method;
        options.headers = {
            "Content-Type": "application/json",
            ...options.headers,
        };

        if (method === HttpMethods.GET) {
            req_url = formatHTTPGetUrl(req_url, params);
        } else {
            options.body = JSON.stringify(params);
        }

        try {
            const response = await fetch(req_url, options);

            return handleErrors(response);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    static async get(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaPacyFetch.request(
            url,
            HttpMethods.GET,
            params,
            options,
        );
    }

    static async post(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaPacyFetch.request(
            url,
            HttpMethods.POST,
            params,
            options,
        );
    }

    static async patch(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaPacyFetch.request(
            url,
            HttpMethods.PATCH,
            params,
            options,
        );
    }

    static async put(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaPacyFetch.request(
            url,
            HttpMethods.PUT,
            params,
            options,
        );
    }

    static async delete(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaPacyFetch.request(
            url,
            HttpMethods.DELETE,
            params,
            options,
        );
    }
}

export default HandleJavaPacyFetch;
