function parseDate() {
    // 获取当前时间戳
    const currentTimestamp = Date.now();

    // 创建一个日期对象
    const currentDate = new Date(currentTimestamp);

    // 获取年、月、日、小时和分钟
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); //月份从0开始，需要加1
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    // 格式化为"YYYY-MM-DD HH:mm"格式
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
}

export function prometheusAlertServer({
    req,
    annotations_description,
    req_url,
    status_code,
}: {
    req: any;
    annotations_description: string;
    req_url: string;
    status_code: number;
}) {
    try {
        // 仅仅在生产环境发送告警
        if (!process.env.PROMETHEUS_ALERT) {
            return;
        }

        const postBody = {
            alerts: [
                {
                    status: "firing",
                    labels: {
                        alertname: "web-interface",
                        instance: req.host,
                        level: "3",
                    },
                    annotations: {
                        title: "接口调用失败",
                        req_url: req_url,
                        status_code: status_code,
                        description: annotations_description,
                    },
                    startsAt: parseDate(),
                    endsAt: parseDate(),
                },
            ],
        };
        const postOpt = {
            method: "POST",
            body: JSON.stringify(postBody),
        };

        fetch(process.env.PROMETHEUS_ALERT, postOpt);
    } catch (e) {
        console.log("PROMETHEUS_ALERT ERROR : " + e);
    }
}
