import Cookies from "js-cookie";
import { JWT_COOKIE_NAME, LOCAL_STORAGE_NAMES } from "../config/constConf";
import localforage from "localforage";
import { isEmpty } from "lodash";
import { IMG_HOST, S3_URL } from "@/config/api";
import { IProductPrice } from "@/src/components/ColorSelect/GlassColor";
import { isLogin } from "@/src/models/loginModel";
import { IGlass } from "@/src/app/basket/type";
import {
    BASKET_GOODS,
    IAddOn,
    IClips,
    IProducts,
    IVirtual,
} from "@/src/hooks/useAnalysis/utils";
//封装 cookie-js
export function setCookieItem(
    name: string,
    value: string,
    options?: Cookies.CookieAttributes,
) {
    try {
        if (!value) {
            return false;
        }
        Cookies.set(name, value, options);
        return true;
    } catch (e) {
        return false;
    }
}

export function getCookieItem(name: string) {
    try {
        return Cookies.get(name);
    } catch (e) {
        return "";
    }
}

export function removeCookieItem(name: string, options?: Record<string, any>) {
    try {
        Cookies.remove(name, options);
        return true;
    } catch (e) {
        return false;
    }
}

/**
 * 将数据写入localStorage
 * @param key key
 * @param data data
 * @returns
 */
export function setLocalItem(key: string, data: any) {
    try {
        const temp = data instanceof Object ? JSON.stringify(data) : data;
        window.localStorage.setItem(key, temp);
    } catch (e) {
        throw e;
    }
}

/**
 * 从localStorage获取参数
 * @param key
 * @returns
 */
export function getLocalItem(key: string) {
    try {
        const localStr = window.localStorage.getItem(key);
        return localStr ? JSON.parse(localStr) : null;
    } catch (e) {
        return window.localStorage.getItem(key);
    }
}

export function removeLocalItem(key: string) {
    try {
        window.localStorage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

export function getSession(key: string) {
    let result = undefined;
    try {
        result = window.sessionStorage.getItem(key);
        return result ? JSON.parse(result) : null;
    } catch (error) {
        return result;
    }
}

// 封装session
export function setSession(key: string, value: any) {
    // 兼容老项目，引用类型做json.stringify处理，暂时默认引用类型只有数组和对象
    const tempValue = value instanceof Object ? JSON.stringify(value) : value;
    try {
        window.sessionStorage.setItem(key, tempValue);
    } catch (err) {
        throw err;
    }
}

const isServer = typeof window === "undefined";

// 加0
export function addZero(num: number | string) {
    if (Number(num) < 10) {
        num = "0" + num;
    }
    return num;
}

export function getUrlArgStr() {
    let result: Record<string, any> = {};
    let url = window.location.search;
    const reg = /[?&][^?&]+=[^?&]+/g;
    const arr = url.match(reg);
    if (arr) {
        arr.forEach((v) => {
            let item = v.substring(1).split("=");
            result[item[0]] = item[1];
        });
    }

    return result;
}

//改变网址却不需要刷新页面
export function formattingUrl(
    condition: Record<string, any>,
    preRouter: string,
) {
    if (!condition) {
        return;
    }
    let url: string = window.location.pathname + "?";
    let _query = "";
    for (let i in condition) {
        if (condition[i]) {
            _query += i + "=" + condition[i] + "&";
        }
    }

    url += _query;
    const asUrl = url.substring(0, url.length - 1);
    let _url = asUrl;
    if (preRouter) {
        _url =
            preRouter.indexOf("?") > 0
                ? `${preRouter}&${_query}`
                : `${preRouter}?${_query}`;
    }
    const _state = {
        as: asUrl,
        options: {},
        url: _url,
    };
    window.history.replaceState(_state, "", asUrl);
}

interface IJointImg {
    productId: number;
    size: string;
    imgTotal: number;
    name: string;
    imgHost?: string;
}
/**
 * jointImg
 * 
 * @param productId,
    @param size,
    @param imgTotal, 正面图=>2
    @param name,
    @param imgHost, 
 * @returns ${newImgHost}/p/${productId}/${size}${imgTotal}-${name}
 */
export function jointImg({
    productId,
    size,
    imgTotal,
    name,
    imgHost,
}: IJointImg) {
    size = size !== "" ? size + "-" : "";

    if (name?.startsWith("http")) {
        return name;
    }

    return `${IMG_HOST()}/p/${productId}/${size}${imgTotal}-${name}`;
}

//色号文案翻译
export function tranColorByName({ t, color_name }: any) {
    let res = [];
    if (color_name.includes(",")) {
        let classes = color_name.split(",");
        classes.map((val: any) => {
            if (val) {
                let _color = t(`common:FrameColor.${val}`);
                res.push(_color);
            }
        });
    } else {
        let _color = t(`common:FrameColor.${color_name}`);
        res.push(_color);
    }

    return res.join(", ");
}

// 数组去重
export function uniqueArray<T>(arr: T[]): T[] {
    const set = new Set();
    return arr.filter((item) => {
        const serializedItem =
            item instanceof Object ? JSON.stringify(item) : item;
        if (set.has(serializedItem)) {
            return false;
        }
        set.add(serializedItem);
        return true;
    });
}

export function youTuBeVideoWH(e: any, V: any) {
    const W = e.clientWidth;
    e.height = W / V;
}

export const randomString = () => {
    return Math.random().toString(36).substring(2, 15);
};

// 获取存在本地的touristid
const getTouristId = () => {
    return new Promise((resolve, reject) => {
        localforage.getItem(LOCAL_STORAGE_NAMES.TOURIST_ID, (err, id) => {
            if (err) {
                reject(err);
                return;
            }
            if (id) {
                resolve(id);
                return;
            }
            const fresh = randomString();
            localforage.setItem(
                LOCAL_STORAGE_NAMES.TOURIST_ID,
                fresh,
                (err) => {
                    console.error(err);
                    if (!err) {
                        resolve(fresh);
                    } else {
                        reject(err);
                    }
                },
            );
        });
    });
};

//将对象转化为url参数字符串
export const serialize = function (obj: Record<string, any>) {
    const str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

//判断新老用户
//指定显示用户类型 1、全部用户 2已登录已下单用户 3、未登录用户&已登录未下单用户 4、未登录用户 5、已登录未下单
export function initTopAd(data: any[]) {
    if (!data || data.length < 1) {
        return [];
    }
    //后台返回判断新老用户
    const is_old_customer = !!data[0].is_old_customer;
    //确定用户类型
    let user_type = 4;
    if (is_old_customer) {
        user_type = 2;
    }
    if (isLogin()) {
        user_type = 5;
    }

    const ad_match_data = data.filter(
        (item) => item.show_user_type === user_type,
    );

    if (ad_match_data.length) {
        return ad_match_data;
    }

    // 如果用户类型是4或者5。后台配置3也要能生效
    if (user_type === 4 || user_type === 5) {
        const threeItems = data.filter((item) => item.show_user_type === 3);
        if (threeItems.length) {
            return threeItems;
        }
    }

    const defultData = data.filter((item) => item.show_user_type === 1);
    return defultData.length > 0 ? defultData : [];
}

// 判断是否包含其中之一
export function includesOneOfArr(str: string, arr: any[]) {
    let res = false;
    const result = arr.filter((item) => str.includes(item));
    res = isEmpty(result);
    return !res;
}
//展示tryon图片
export function jointTryOnImg(productId: number, colorId: number) {
    return `${IMG_HOST()}/p/${productId}/tryon-${colorId}.png`;
}

// 时间转换，输入时间戳，输出年月日
export function transDate(data: number) {
    const date = new Date(data * 1000);
    const month = date.toDateString().split(" ")[1];
    const day = date.getDate();
    const year = date.getFullYear();
    return { year, month, day };
}

export function productSrc(data: Record<string, any>) {
    return jointImg({
        productId: data.products_id,
        size: "middle",
        imgTotal: 2,
        name: data.products_image,
    });
}

//js精度计算
export const priceFixed = (value: string) => {
    let resVal = parseFloat(value.replace(/,/g, ""));
    let num = Math.round(resVal * 100);
    let cents = num % 100;
    let centsRes = cents < 10 ? "0" + cents : cents;
    let numRes = Math.floor(num / 100).toString();

    return `${numRes}.${centsRes}`;
};
// 判断是否是IE
export function isIE() {
    if (isServer) {
        return false;
    }
    if (typeof window.isIE === "boolean") {
        return window.isIE;
    }

    window.isIE = !!window.ActiveXObject || "ActiveXObject" in window;
    return window.isIE;
}

/**
 * 判断打折时间是否过期，判断数量限制是否到达库存
 *
 * @param {*} productPrice
 * @returns
 */
export function showPrice(productPrice?: IProductPrice): {
    price: number | string;
    showDiscount: boolean;
    salePer: number;
} {
    if (!productPrice) {
        return {
            price: 0,
            showDiscount: false,
            salePer: 0,
        };
    }
    const now: number = Math.floor(Date.now() / 1000);

    const { price, discount_start, discount_expire, discount_price } =
        productPrice;

    if (!discount_start || !discount_expire) {
        return {
            price,
            showDiscount: false,
            salePer: discount_price ? salePercent(price, discount_price) : 0,
        };
    }

    if (now > discount_start && now < discount_expire) {
        return {
            price: discount_price,
            showDiscount: true,
            salePer: discount_price ? salePercent(price, discount_price) : 0,
        };
    }

    return {
        price,
        showDiscount: false,
        salePer: discount_price ? salePercent(price, discount_price) : 0,
    };
}

// 促销百分比
export function salePercent(
    price: number | string,
    salePrice: number | string,
): number {
    const numericPrice = parseFloat(price.toString());
    const numericSalePrice = parseFloat(salePrice.toString());
    return Math.round((100 * (numericPrice - numericSalePrice)) / numericPrice);
}

// 格式化时间，倒计时用
export function filterTime(lastTime: number) {
    if (lastTime < 0) {
        lastTime = 0;
    }

    const day = lastTime > 86400 ? Math.floor(lastTime / 86400) : 0;
    lastTime = lastTime - day * 86400;

    const hour = lastTime > 3600 ? Math.floor(lastTime / 3600) : 0;
    lastTime = lastTime - hour * 3600;

    const min = lastTime > 60 ? Math.floor(lastTime / 60) : 0;
    lastTime = lastTime - min * 60;

    const second = lastTime;

    return {
        day: addZero(day),
        hour: addZero(hour),
        min: addZero(min),
        second: addZero(second),
    };
}

// 周年庆活动时间范围内
export const inTwelfthAnniversaryRange = false;

//上架小于一周属于新品
const weekSec = 604800;
const monthSec = 2626560;
export function isNew(addTime: number) {
    return new Date().getTime() / 1000 - addTime < monthSec;
}

export const mixAddOnsImage = (
    addOnId: number,
    name: string,
    size = "small-",
) => {
    return `addons/${addOnId}/${size}${name}`;
};

/*
接受一个参数,拼接图片url
*/
export function jointImgUploadByUser(url: string, host = IMG_HOST()) {
    if (!url) {
        return "";
    }
    if (/https?/.test(url)) {
        //全地址
        return url;
    } else {
        return `${host}/${url}`;
    }
}

/*
输出图片string
    fullUrl: PropTypes.bool, //全路径
    halfUrl: PropTypes.bool, //半路径，不含host
    onlyName: PropTypes.bool, //只有名字
    默认回传全路径
*/
interface IExportImgUrlProps {
    fullUrl: boolean;
    halfUrl: boolean;
    onlyName: boolean;
    url: string;
}
export function exportImgUrl({
    fullUrl,
    halfUrl,
    onlyName,
    url,
}: IExportImgUrlProps) {
    switch (true) {
        case fullUrl:
            return url;
        case halfUrl:
            return url.replace(S3_URL() || "", "");
        case onlyName:
            return url.split("/")[url.split("/").length - 1];
        default:
            return url;
    }
}

export const jointZtImg = (
    pageId: number,
    key?: string,
    imgHost = IMG_HOST(),
) => {
    if (!key) {
        return key;
    }
    // 拼接URL并移除多余的斜杠
    return `${imgHost}/zt/banner/${pageId}/${key}`.replace(
        /([^:]\/)\/+/g,
        "$1",
    );
};

/**
 * 页面不刷新替换url
 * @param {*} searchName 需要替换的路径参数名称
 * @param {*} newValue 需要替换的路径参数值
 */
export function replaceUrl(searchName: string, newValue: string) {
    // 获取当前URL的参数
    var urlParams = new URLSearchParams(window.location.search);
    // 设置新的color值
    urlParams.set(searchName, newValue); // 将'newColorValue'替换为你想要的颜色值
    // 构建新的URL
    var newUrl = window.location.pathname + "?" + urlParams.toString();
    // 使用replaceState替换URL
    window.history.replaceState(null, "", newUrl);
}

/**
 输出随机数，范围自定,保留两位小数
 *
 * @export
 * @param {*} min
 * @param {*} max
 */
export function getRandom(min: number, max: number, isInt?: boolean) {
    let a = Math.random();
    a = !isInt
        ? Number((min + a * (max - min)).toFixed(2))
        : Number(min + Math.floor(a * (max - min)));
    return a;
}

// 是否在范围内
export function inScope(
    v: string | number,
    {
        min,
        max,
    }: {
        min: number;
        max: number;
    },
) {
    v = typeof v === "string" ? parseFloat(v) : v;
    if (v >= min && v <= max) {
        return true;
    }
    return false;
}

// 单个值是否在范围内
export type Range = Record<"min" | "max", number>;
export function isInScope(v: string | number, scope: Range) {
    const res = Array.isArray(scope)
        ? scope.find((item) => inScope(v, item))
        : inScope(v, scope);
    if (res === 0) {
        return true;
    } else {
        return !!res;
    }
}

export function loginOrNotNoJump() {
    const jwt = getCookieItem(JWT_COOKIE_NAME);
    return !!jwt;
}

// 时间格式化，输入时间戳，输出年月日
export function dateTrans(date: number) {
    let time = transDate(date);
    return time.month + " " + time.day + " , " + time.year;
}

// 空字符串，undefined转化为0
export function mapNullStrToZero(v: number | string | undefined) {
    if (
        (typeof v === "string" && v.trim().length === 0) ||
        typeof v === "undefined"
    ) {
        return 0;
    }
    return typeof v === "number" ? v : parseFloat(v);
}

// 单个数组是否在范围外
export function outOfScope(
    v: number | string,
    scope: {
        max: number;
        min: number;
    },
) {
    const res = Array.isArray(scope)
        ? scope.find((item) => inScope(v, item))
        : inScope(v, scope);
    if (res === 0) {
        return false;
    } else {
        return !res;
    }
}

// 数组中是否有值在范围外
export function arrOutOfScope(
    arr: (string | number)[],
    scope: {
        max: number;
        min: number;
    },
) {
    const res = Array.isArray(arr)
        ? arr.find((item) => outOfScope(item, scope))
        : isInScope(arr, scope);
    if (res === 0) {
        return true;
    } else {
        return !!res;
    }
}

//客户端渲染判断是否是PC端
export function isPC() {
    if (isServer) {
        return false;
    }
    let userAgentInfo = navigator.userAgent;
    const Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
    ];
    let flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}

export function mapIdsAndPriceOfProducts(products: IGlass[], price: number) {
    return {
        ids: products.map((item) => item.product_id),
        price,
        fb_content: products.map(
            (item) => `${item.product_id}-${item.color_id}`,
        ),
    };
}

// 判断是否售罄或者
export function isUnderStock(props: BASKET_GOODS) {
    const { goods } = props;
    if (goods === "glass" || goods === "glasses") {
        return (
            props.is_under_stock ||
            !props.products_selling ||
            !props.products_color_selling
        );
    } else {
        return (
            !(props as IAddOn | IVirtual).is_selling ||
            (props as IAddOn).is_under_stock
        );
    }
}

// 判断(产品眼镜或者clips)是否下架
export function isUnderSelling(args: IClips | IProducts) {
    const { goods } = args;
    if (goods === "glass" || goods === "glasses") {
        return (
            !(args as IProducts).products_selling ||
            !(args as IProducts).products_color_selling
        );
    } else {
        return !(args as IClips).is_selling;
    }
}

export { isServer, getTouristId };
