import JSEncrypt from "jsencrypt";

export const encryptVasl = (): string => {
    let rsaPassWord = "";
    try {
        const encryptor = new JSEncrypt(); // 创建加密对象实例
        // 之前 SSL 生成的公钥，复制的时候要小心不要有空格
        const pubKey = `-----BEGIN PUBLIC KEY-----${
            typeof window === "undefined"
                ? process.env.RECORD_KRY
                : window.process.env.RECORD_KRY
        }-----END PUBLIC KEY-----`;
        encryptor.setPublicKey(pubKey); // 设置公钥
        rsaPassWord = encryptor.encrypt(generateRandomPassword(20)) || "";
    } catch (e) {
        console.log(e);
        rsaPassWord = "";
    }

    return rsaPassWord;
};

function generateRandomPassword(length: number): string {
    const charset =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let password = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    return password;
}

export const encryptValToen = (token: string): string => {
    let rsaPassWord = "";
    try {
        const encryptor = new JSEncrypt(); // 创建加密对象实例
        // 之前 SSL 生成的公钥，复制的时候要小心不要有空格
        const pubKey = `-----BEGIN PUBLIC KEY-----${
            typeof window === "undefined"
                ? process.env.RECORD_KRY
                : window.process.env.RECORD_KRY
        }-----END PUBLIC KEY-----`;
        encryptor.setPublicKey(pubKey); // 设置公钥
        rsaPassWord = encryptor.encrypt(token) || "";
    } catch (e) {
        console.log(e);
        rsaPassWord = "";
    }
    return rsaPassWord;
};

export const splitString = (str: string): [string, string] => {
    const length = str.length;
    const middleIndex = Math.floor(length / 2);

    let firstHalf = str.substring(0, 50);
    let secondHalf = str.substring(50);

    // 处理奇数情况，将中间字符包含在第二半部分
    if (length % 2 !== 0) {
        firstHalf = str.substring(0, middleIndex + 1);
        secondHalf = str.substring(middleIndex + 1);
    }

    return [firstHalf, secondHalf];
};
