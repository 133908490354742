
export { default as HandlePhpFetch } from "./PhpFetch";
export { default as HandleJavaCommonFetch } from "./JavaCommonFetch";

export { default as HandleJavaOpencvFetch } from "./JavaOpencvFetch";

export { default as HandleJavaPacyFetch } from "./JavaPacyFetch";

export { default as HandleJavaReviewFetch } from "./JavaReviewFetch";

export { default as HandleJavaSearchFetch } from "./JavaSearchFetch";
export { default as HandleJavaUsualFetch } from "./JavaUsualFetch";

export {
    replaceUrlByDiffrentFetch,
    getPreUrlByApiTag,
    setServerHeader,
} from "./replaceUrlBydiffrentFetch";
