import { isServer } from "@/lib/commonService";
import { getSite } from "@/src/utils/configUtils";

// 计算汇率，接受汇率信息和当前使用货币code，输出一个货币转化函数，美元=》其他货币
export const calcCurrencies =
    (domain, rateData, currencyCode) => (value, company, currencyValue) => {
        // 非常的操蛋，这样做的原因是当页面出现error的时候，会中断原渲染进程，但此时在根layout.tsx中的逻辑仍会执行，此时逻辑<Script>中的脚本未加载完成，导致访问不了绑定到window的变量而报错，暂时没找到解法
        const site = isServer ? getSite(domain) : self.process.env.SITE;
        if (!rateData || !currencyCode) {
            const getCompleteRateData = (rateData) => {
                try {
                    const { rateData: serverRateData } = isServer
                        ? global.rates
                        : rate;
                    const localCurrencyList = Object.keys(rateData);
                    const serverCurrencyList = Object.keys(
                        serverRateData,
                    ).filter(
                        (currency) => !localCurrencyList.includes(currency),
                    );
                    return [
                        ...rateData,
                        ...serverCurrencyList.reduce((pre, curr) => {
                            pre.push({
                                ...rateData[0],
                                code: curr.code,
                                title: curr.title,
                                symbol_left: curr.symbol_left,
                            });
                            return pre;
                        }, []),
                    ];
                } catch (error) {
                    console.log("ksjz debug getCompleteRateDataError", error);
                    return rateData;
                }
            };
            rateData = getCompleteRateData({
                USD: {
                    code: "USD",
                    title: "US Dollar",
                    symbol_left: "$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                EUR: {
                    code: "EUR",
                    title: "Euro",
                    symbol_left: "€",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                GBP: {
                    code: "GBP",
                    title: "GB Pound",
                    symbol_left: "£",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                CAD: {
                    code: "CAD",
                    title: "Canadian Dollar",
                    symbol_left: "CAD$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                AUD: {
                    code: "AUD",
                    title: "Australian Dollar",
                    symbol_left: "AUD$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                MYR: {
                    code: "MYR",
                    title: "Ringgit Malaysia",
                    symbol_left: "RM",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                MXN: {
                    code: "MXN",
                    title: "Peso Mexicano",
                    symbol_left: "MXN$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                NZD: {
                    code: "NZD",
                    title: "New Zealand Dollar",
                    symbol_left: "NZD$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                SGD: {
                    code: "SGD",
                    title: "Singapore Dollar",
                    symbol_left: "SGD$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                CLP: {
                    code: "CLP",
                    title: "Chile",
                    symbol_left: "CLP$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                ARS: {
                    code: "ARS",
                    title: "Argentina",
                    symbol_left: "CLP$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                BRL: {
                    code: "BRL",
                    title: "Brazilian real",
                    symbol_left: "R$",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                JPY: {
                    code: "JPY",
                    title: "JPY",
                    symbol_left: "¥",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                PLN: {
                    code: "PLN",
                    title: "PLN",
                    symbol_left: "zł",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
                RON: {
                    code: "RON",
                    title: "RON",
                    symbol_left: "lei",
                    symbol_right: "",
                    value: 1,
                    thousands_point: ",",
                    decimal_point: ".",
                    decimal_places: "2",
                    is_deleted: false,
                },
            });
            if (currencyCode) {
                console.log("currencyCode", currencyCode);
                const changeRateData = rateData[currencyCode];
                console.log("changeRateData", changeRateData);
                if (typeof value === "string") value = value.replace(/,/g, "");
                value = parseFloat(value * 100);
                let num = value * changeRateData.value * (currencyValue || 1);
                num = Math.round(num);
                let cents = num % 100;
                cents = cents < 10 ? "0" + cents : cents;
                num = Math.floor(num / 100).toString();
                for (let i = 0; i < Math.floor(num.length - (1 + i) / 3); i++) {
                    const a = num.substring(0, num.length - (4 * i + 3));

                    if (a)
                        num =
                            num.substring(0, num.length - (4 * i + 3)) +
                            changeRateData.thousands_point +
                            num.substring(num.length - (4 * i + 3));
                }
                // if (site === 'mx') {
                //   return `$${num}${changeRateData.decimal_point
                //     }${cents}${company ? changeRateData.code : ''}`;
                // }
                if (changeRateData.code === "EUR") {
                    if (site === "it") {
                        changeRateData.decimal_point = ",";
                        return `${changeRateData.symbol_left}${num}${
                            changeRateData.decimal_point
                        }${cents}${company ? changeRateData.code : ""}`;
                    }
                    if (site == "us" || site == "uk") {
                        return `${changeRateData.symbol_left}${num}${
                            changeRateData.decimal_point
                        }${cents}${changeRateData.symbol_right} ${
                            company ? changeRateData.code : ""
                        }`;
                    }
                    changeRateData.decimal_point = ",";
                    return `${num}${changeRateData.decimal_point}${cents} ${
                        changeRateData.symbol_left
                    }${company ? changeRateData.code : ""}`;
                }

                //智利站货币特殊处理
                if (changeRateData.code === "CLP") {
                    changeRateData.decimal_point = ",";
                    var reg1 = new RegExp(",", "g");
                    if (num.toString().length > 3) {
                        num = `${num}`.replace(reg1, "");
                        num = `${format(num)}`; //千分位加.
                    }
                    return `${changeRateData.symbol_left}${num}${
                        changeRateData.symbol_right
                    } ${company ? changeRateData.code : ""}`;
                }

                if (changeRateData.code === "JPY") {
                    changeRateData.decimal_point = ",";
                    var reg1 = new RegExp(",", "g");
                    if (num.toString().length > 3) {
                        num = `${num}`.replace(reg1, "");
                        num = `${formatPint(num)}`; //千分位加,
                    }
                    return `${changeRateData.symbol_left}${num}${
                        company ? changeRateData.code : ""
                    }`;
                }

                if (changeRateData.code === "PLN") {
                    changeRateData.decimal_point = ",";
                    var reg1 = new RegExp(",", "g");
                    if (num.toString().length > 3) {
                        num = `${num}`.replace(reg1, "");
                        num = `${formatPint(num)}`; //千分位加,
                    }
                    if (cents != "00") {
                        cents = "99";
                    }
                    return `${num}${changeRateData.decimal_point}${cents} ${changeRateData.symbol_left}${
                        company ? changeRateData.code : ""
                    }`;
                }

                if (changeRateData.code === "RON") {
                    changeRateData.decimal_point = ",";
                    var reg1 = new RegExp(",", "g");
                    if (num.toString().length > 3) {
                        num = `${num}`.replace(reg1, "");
                        num = `${formatPint(num)}`; //千分位加,
                    }
                    return `${num} ${changeRateData.symbol_left}${
                        company ? changeRateData.code : ""
                    }`;
                }

                // 巴西站货币特殊处理
                if (changeRateData.code === "BRL") {
                    changeRateData.decimal_point = ",";
                    const reg1 = new RegExp(",", "g");
                    if (num.toString().length > 3) {
                        num = `${num}`.replace(reg1, "");
                        num = `${format(num)}`; //千分位加.
                    }
                    if (num) num = num + ",00";
                    return `${changeRateData.symbol_left}${num}${
                        changeRateData.symbol_right
                    } ${company ? changeRateData.code : ""}`;
                }
                // 输出格式化的货币
                return `${changeRateData.symbol_left}${num}${
                    changeRateData.decimal_point
                }${cents}${changeRateData.symbol_right} ${
                    company ? changeRateData.code : ""
                }`;
            }
            //没有货币数据，展示默认站点货币
            value = priceFixed(value);
            const defaultRateData = getSiteConfiguration("default_cur", domain);
            if (!defaultRateData) {
                return value;
            }
            const res =
                defaultRateData.code === "EUR"
                    ? `${value} ${defaultRateData.symbol_left}`
                    : `${defaultRateData.symbol_left}${value}`;
            return res;
        }
        const changeRateData = rateData[currencyCode];
        if (typeof value === "string") value = value.replace(/,/g, "");
        value = parseFloat(value * 100);
        let num = value * changeRateData.value;
        num = Math.round(num);
        let cents = num % 100;
        cents = cents < 10 ? "0" + cents : cents;
        num = Math.floor(num / 100).toString();
        for (let i = 0; i < Math.floor(num.length - (1 + i) / 3); i++) {
            const a = num.substring(0, num.length - (4 * i + 3));

            if (a)
                num =
                    num.substring(0, num.length - (4 * i + 3)) +
                    changeRateData.thousands_point +
                    num.substring(num.length - (4 * i + 3));
        }
        // if (site === 'mx') {
        //   return `$${num}${changeRateData.decimal_point
        //     }${cents}${company ? changeRateData.code : ''}`;
        // }
        if (changeRateData.code === "EUR") {
            if (site === "us" || site === "uk") {
                return `${changeRateData.symbol_left}${num}${
                    changeRateData.decimal_point
                }${cents}${changeRateData.symbol_right} ${
                    company ? changeRateData.code : ""
                }`;
            }
            if (site === "it") {
                changeRateData.decimal_point = ",";
                return `${changeRateData.symbol_left}${num}${
                    changeRateData.decimal_point
                }${cents}${company ? changeRateData.code : ""}`;
            }
            changeRateData.decimal_point = ",";
            return `${num}${changeRateData.decimal_point}${cents} ${
                changeRateData.symbol_left
            }${company ? changeRateData.code : ""}`;
        }
        if (changeRateData.code === "CLP") {
            changeRateData.decimal_point = ",";
            var reg1 = new RegExp(",", "g");
            if (num.toString().length > 3) {
                num = `${num}`.replace(reg1, "");
                num = `${format(num)}`; //千分位加.
            }
            return `${changeRateData.symbol_left}${num}${
                company ? changeRateData.code : ""
            }`;
        }

        if (changeRateData.code === "JPY") {
            changeRateData.decimal_point = ",";
            var reg1 = new RegExp(",", "g");
            if (num.toString().length > 3) {
                num = `${num}`.replace(reg1, "");
                num = `${formatPint(num)}`; //千分位加.
            }
            return `${changeRateData.symbol_left}${num}${
                company ? changeRateData.code : ""
            }`;
        }

        if (changeRateData.code === "PLN") {
            changeRateData.decimal_point = ",";
            var reg1 = new RegExp(",", "g");
            if (num.toString().length > 3) {
                num = `${num}`.replace(reg1, "");
                num = `${formatPint(num)}`; //千分位加,
            }
            if (cents != "00") {
                cents = "99";
            }
            return `${num}${changeRateData.decimal_point}${cents} ${changeRateData.symbol_left}${
                company ? changeRateData.code : ""
            }`;
        }

        if (changeRateData.code === "RON") {
            changeRateData.decimal_point = ",";
            var reg1 = new RegExp(",", "g");
            if (num.toString().length > 3) {
                num = `${num}`.replace(reg1, "");
                num = `${formatPint(num)}`; //千分位加,
            }
            return `${num} ${changeRateData.symbol_left}${
                company ? changeRateData.code : ""
            }`;
        }

        // 巴西站货币特殊处理
        if (changeRateData.code === "BRL") {
            changeRateData.decimal_point = ",";
            var reg1 = new RegExp(",", "g");
            if (num.toString().length > 3) {
                num = `${num}`.replace(reg1, "");
                num = `${format(num)}`; //千分位加.
            }
            if (num) num = num + ",00";
            return `${changeRateData.symbol_left}${num}${changeRateData.symbol_right} ${
                company ? changeRateData.code : ""
            }`;
        }

        // 输出格式化的货币
        return `${changeRateData.symbol_left}${num}${
            changeRateData.decimal_point
        }${cents}${changeRateData.symbol_right}${company ? changeRateData.code : ""}`;
    };
