//封装gtag相关统计事件
//调用下面方法的时候请确保，ga已注入（window.gtag为函数）
//每个函数的具体参数，请参考url:https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_product_impressions
import { getCookieItem, getLocalItem, isPC } from "@/lib/commonService";
import { CURRENCY_COOKIE_NAME } from "@/config/constConf";
import { getEnv, getSiteConfiguration } from "@/src/utils/configUtils";

//1.衡量商品展示
function productShow(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "view_item_list", prams);
            // console.log('ga view_item_list', prams);
        })
        .catch((e) => {
            console.log(e);
        });
}
// 2.衡量商品点击
function productClick(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "select_item", prams);
            // console.log('ga select_item', prams);
        })
        .catch((e) => {
            console.log(e);
        });
}
// 3.衡量商品详情获得的浏览
function productDetailBrowse(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "view_item", prams);
            // console.log('ga view_item', prams);
        })
        .catch((e) => {
            console.log(e);
        });
}
// 4.衡量将商品添加到购物车
function productAddToCar(prams = {}) {
    window.gtag && window.gtag("event", "add_to_cart", prams);
    console.log("ga add_to_cart", prams);
}

// 查看了购物车。

function productViewCart(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "view_cart", prams);
            // console.log('ga view_cart', prams);
        })
        .catch((e) => {
            console.log(e);
        });
}

// 5.衡量将商品从购物车中移除商品的操作
function productRemoveFromCar(prams = {}) {
    window.gtag && window.gtag("event", "remove_from_cart", prams);
}
// 6.衡量结帐情况
// a.要衡量第一个结帐步骤，请发送一个包含结帐商品的 begin_checkout 事件：
function productBeginCheckout(prams = {}) {
    window.gtag && window.gtag("event", "begin_checkout", prams);
}
// 要衡量每个后续结帐步骤，请发送一个包含结帐商品的 add_payment_info 事件：
function productsCheckoutProgress(prams = {}) {
    window.gtag && window.gtag("event", "add_payment_info", prams);
}
// b. 衡量结帐选项
function productSetCheckoutOpt(prams = {}) {
    window.gtag && window.gtag("event", "set_checkout_option", prams);
}
// 7衡量购买
function productsPurchase(prams = {}) {
    window.gtag && window.gtag("event", "purchase", prams);
}

// 8点击查看物流说明
function checkDelivey(prams = {}) {
    window.gtag && window.gtag("event", "check_delivey", prams);
}

// 9专题页选购镜片
function specialSubSelectLens(prams = {}) {
    window.gtag && window.gtag("event", "specialSub_select_lens", prams);
}

// 10购物车收藏商品
function basketFavoriteClick(prams = {}) {
    window.gtag && window.gtag("event", "basket_favorite_click", prams);
}

// 11商品详情页推荐商品点击
function ProuctLikeOrHotClick(prams = {}) {
    window.gtag && window.gtag("event", "Product_like_hot_click", prams);
}
//12 统计评价按钮点击
function gaWriteReviewClick(prams = {}) {
    window.gtag && window.gtag("event", "write_review", prams);
}
// 统计评价按钮点击 write_comment
function gaWriteCommentClick(prams = {}) {
    window.gtag && window.gtag("event", "write_comment", prams);
}
//
// try on-upload my photo 上传图片
function gaUploadImgClick(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            // 对upload photo分别增加标签PC；mobile
            window.gtag &&
                window.gtag("event", "upload photo", {
                    event_category: "try on",
                    event_label: isPC() ? "PC" : "mobile",
                    ...prams,
                });
        })
        .catch((e) => {
            console.log(e);
        });
}
//try on use
function gaTryOnUseEvent(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag &&
                window.gtag("event", "try on use", {
                    event_category: "try on",
                    event_label: window.location.pathname,
                    ...prams,
                });
        })
        .catch((e) => {
            console.log(e);
        });
}
//首页banner点击 Ga统计
function gaIndexBannerClick(idx, prams = {}) {
    idx !== undefined &&
        gaCommonEvent(`banner${idx}`, {
            event_category: "index",
            ...prams,
        });
}
function gaIndexSubBannerClick(idx, prams = {}) {
    idx !== undefined &&
        gaCommonEvent(`attach${idx}`, {
            event_category: "index",
            ...prams,
        });
}
//首页快速入口点击
function gaIndexCategoryEntranceClick(idx, prams = {}) {
    idx !== undefined &&
        gaCommonEvent(`entrance${idx}`, {
            event_category: "index",
            ...prams,
        });
}
//首页 第三个出广告为点击
function gaIndexSmallADClick(idx, prams = {}) {
    idx !== undefined &&
        gaCommonEvent(`smallad${idx}`, {
            event_category: "index",
            ...prams,
        });
}
//size guide click
function sizeGuideClick(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "size_guide_click", prams);
        })
        .catch((e) => {
            console.log(e);
        });
}
//ga 普通通用统计事件
function gaCommonEvent(name, prams = {}) {
    if (!name) return;
    console.log("gaCommonEvent", name, prams);
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", name, prams);
        })
        .catch((e) => {
            console.log(e);
        });
}
//新用户引导提交
function gaCustomerGuideSubmitEvent(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "customer guide_submit", prams);
        })
        .catch((e) => {
            console.log(e);
        });
}

// nav referral click
function referralClick(prams = {}) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "referral", prams);
        })
        .catch((e) => {
            console.log(e);
        });
}
//物流数据埋点 类型：track order
function gaLogisticsHelpClick(params = {}) {
    gaCommonEvent("help clink", {
        event_category: "track order",
        ...params,
    });
}
function gaLogisticsHelpSearch(params = {}) {
    gaCommonEvent("help search", {
        event_category: "track order",
        ...params,
    });
}
function gaLogisticsQuery(params = {}) {
    gaCommonEvent("logistics query", {
        event_category: "track order",
        ...params,
    });
}
function gaLogisticsOrderTrack(params = {}) {
    gaCommonEvent("place order track", {
        event_category: "track order",
        ...params,
    });
}
function gaLogisticsOrderListTrack(params = {}) {
    gaCommonEvent("order list track", {
        event_category: "track order",
        ...params,
    });
}
//支付失败
function gaPaymentFailed(params = {}) {
    // payment_failed event_label
    gaCommonEvent("payment_failed", {
        event_category: "ecommerce",
        ...params,
    });
}
//进入待支付页面
function gaIntoRecheckPage(params = {}) {
    gaCommonEvent("waiting_payment_view", {
        event_category: "waiting payment",
        ...params,
    });
}
//待支付 发起支付
function gaRecheckPageCheckOutProcess(params = {}) {
    gaCommonEvent("waiting_payment_checkout_progress", {
        event_category: "waiting payment",
        ...params,
    });
}
//商城公告栏 ga事件
function gaTopAdClick(params = {}) {
    gaCommonEvent("Bulletin board_Click", {
        ...params,
    });
}
// 商城气泡广告 点击事件 事件标签  event_label : Bubble_01 Bubble_02 Bubble_03
function gaBubbleClick(params = {}) {
    gaCommonEvent("Bubble ad_Click", {
        event_category: "Advertising space",
        ...params,
    });
}
//商城 动态专题页底部广告
function gaProductPageBottomAdClick(params = {}) {
    //统计专题页路径
    gaCommonEvent("Bottom ad of dynamic_Click", {
        event_category: "Advertising space",
        event_label: window.location.pathname,
        ...params,
    });
}
//专题页产品列表嵌入广告 event_label : advertisement_01/advertisement_02/advertisement_03/advertisement_04
function gaProductsPageInsertAdClick(params = {}) {
    gaCommonEvent("Special page_advertising_Click", {
        event_category: "Advertising space",
        ...params,
    });
}
// my account 页面顶部广告位
function gaMyAccountAdClick(params = {}) {
    gaCommonEvent("my account_ad_Click", {
        event_category: "Advertising space",
        ...params,
    });
}
//导航栏搜索框点击
function gaNavSearchInputClick(params = {}) {
    gaCommonEvent("search_click", {
        event_category: "click",
        event_label: isPC() ? "PC" : "mobile",
        ...params,
    });
}
//工具函数获取商品展示参数
/**
 *
 * @param {*} params 商品参数 { productList，colorIndex,list_name,category}
 * productList 商品list
 * colorIndex 展示相同商品id，颜色的索引
 * list_name 商品列表名称
 * category 商品分类名称
 */
function getProductItemParams(params) {
    const { productList } = params;
    if (!productList || !productList.map) return [];
    return productList.map((item, index) => {
        let { products_id, products_model } = item;
        return {
            item_id: products_id,
            id: products_id,
            name: products_model || "",
            item_name: products_model || "",
        };
    });
}
//检测GA是否加载完成
function gaLoadedPromise() {
    let timer = null;
    return new Promise((rev, rej) => {
        if (window && window.gtag) {
            rev(true);
        } else {
            timer = setInterval(() => {
                if (window && window.gtag) {
                    rev(true);
                    clearInterval(timer);
                }
            }, 50);
        }
    });
}

//select lens btn click
function gaSelectLensBtnClick(isOld) {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "select lens", {});
        })
        .catch((e) => {
            console.log(e);
        });
}

//Continue to pay click
function gaContinuePayClick() {
    gaLoadedPromise()
        .then(() => {
            window.gtag &&
                window.gtag("event", "continue_to_pay", {
                    event_category: "waiting payment",
                });
        })
        .catch((e) => {
            console.log(e);
        });
}

//套餐与升级相关ga统计
function gaLensPackageEvent(name, lable) {
    gaLoadedPromise()
        .then(() => {
            // 对upload photo分别增加标签PC；mobile
            window.gtag &&
                window.gtag("event", name, {
                    event_label: lable,
                });
        })
        .catch((e) => {
            console.log(e);
        });
}

function gaContinueShopping() {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "Continue Shopping");
        })
        .catch((e) => {
            console.log(e);
        });
}

function gaStartALiveChat() {
    gaLoadedPromise()
        .then(() => {
            window.gtag && window.gtag("event", "Start a Live Chat");
        })
        .catch((e) => {
            console.log(e);
        });
}

//老带新数据埋点
function gaReferral(name, lable = "") {
    console.log("gaReferral", name);
    //统计专题页路径
    gaCommonEvent(name, {
        event_category: "referral",
        event_label: lable,
    });
}

function addGaConversion(label, price, domain) {
    const GAADId_1 = getEnv("GAADId_1", domain);
    console.log("addGaConversion", `${GAADId_1}/${label}`);
    window.gtag("event", "conversion", {
        send_to: `${GAADId_1}/${label}`,
        value: price,
        currency: getCookieItem(CURRENCY_COOKIE_NAME),
    });
}

// 添加眼镜到购物车 埋点
function addToCartGaConversion(price, domain) {
    let ga_addtocart_conversion =
        getSiteConfiguration("ga_addtocart_conversion", domain) ||
        "cgMPCMKo8L4CEMnC0LEB";
    addGaConversion(ga_addtocart_conversion, price, domain);
}

// 开始结算 埋点
function beginCheckoutGaConversion(price, domain) {
    let ga_checkout_conversion =
        getSiteConfiguration("ga_checkout_conversion", domain) ||
        "ETJDCPDqtKQCEMnC0LEB";
    addGaConversion(ga_checkout_conversion, price, domain);
}

// 首页渲染文案链接 埋点
function propagandaLink(name, prams) {
    gaCommonEvent(name, prams);
}

// 点击商品详情页入口
function clickEntryInDetailPage() {
    const isLogin = !!getCookieItem("jwt");
    const isOldCustomer = !!getLocalItem("is_old_customer");
    gaCommonEvent("view event_box", {
        event_category: "details_page_promotions",
        event_label: !isLogin
            ? "new"
            : isOldCustomer
              ? "old_logged"
              : "new_logged",
    });
}

// 加入镜盒到购物车
function addFreeBoxToCart() {
    const isLogin = !!getCookieItem("jwt");
    const isOldCustomer = !!getLocalItem("is_old_customer");
    gaCommonEvent("add to cart_box", {
        event_category: "details_page_promotions",
        event_label: !isLogin
            ? "new"
            : isOldCustomer
              ? "old_logged"
              : "new_logged",
    });
}

// 重新添加镜盒
function reAddFreeBoxToCart(event_label) {
    gaCommonEvent("add it again_box", {
        event_category: "details_page_promotions",
        event_label,
    });
}

// 领取免费镜盒code事件
function getCodeOfFreeBox() {
    gaCommonEvent("get code_box", {
        event_category: "details_page_promotions",
    });
}

// 倒计时结束
function timeOver(event_label) {
    gaCommonEvent("time over", {
        event_category: "details_page_promotions",
        event_label,
    });
}

// 没有镜盒弹窗出现
function noFreeBoxShow(event_label) {
    gaCommonEvent("sold out", {
        event_category: "details_page_promotions",
        event_label,
    });
}

// 结算页未使用code
function unusedCodeBox(coupon) {
    const event_label = !coupon
        ? "direct order"
        : coupon.toUpperCase() !== "12YEAR" &&
            coupon.toUpperCase() !== "BIRTH30"
          ? "coupon error"
          : "";
    if (event_label) {
        gaCommonEvent("unused code_box", {
            event_category: "details_page_promotions",
            event_label,
        });
    }
}

// 去分站
function gaGoSubstation(event_label) {
    gaCommonEvent("switch", {
        // 操作
        event_category: "site popup",
        event_label,
    });
}
// 点击下拉框
function gaChooseCountry(event_label) {
    gaCommonEvent("choose", {
        // 操作
        event_category: "site popup",
        event_label,
    });
}
// 点击关闭
function gaCloseChooseModal() {
    gaCommonEvent("close", {
        // 操作
        event_category: "site popup",
    });
}

// myfit入口点击 filter/topnav
function clickMyfitEntry(event_label) {
    gaCommonEvent("find myfit", {
        event_category: "myfit",
        event_label,
    });
}

// myfit点击找不到尺寸
function clickMyfitCanotFindSize() {
    gaCommonEvent("no size", {
        event_category: "myfit",
    });
}

// myfit点击提交成功
function clickMyfitSubmitSuccess(size) {
    gaCommonEvent("submit", {
        event_category: "myfit",
        event_label: size.join(","),
    });
}

// myfit点击提交，出现提示
function clickMyfitSubmitError() {
    gaCommonEvent("submit", {
        event_category: "myfit",
        event_label: "error",
    });
}

// myfit点击编辑 filter/topnav
function clickMyfitEdit(event_label) {
    gaCommonEvent("edit", {
        event_category: "myfit",
        event_label,
    });
}

// myfit点击删除 filter/topnav
function clickMyfitDel(event_label) {
    gaCommonEvent("delete", {
        event_category: "myfit",
        event_label,
    });
}

//搜索埋点，
//search_category:搜索类别
//search_result_num:搜索结果数量
//search_term:搜索关键词
function searchCotentClick({
    search_category,
    search_result_num,
    search_term,
}) {
    gaCommonEvent("search", {
        search_category,
        search_result_num,
        search_term,
    });
}

export {
    productShow, //商品展示
    productClick, //商品点击
    productDetailBrowse, //商品详情获得的浏览
    productAddToCar, //添加到购物车
    productRemoveFromCar, //购物车移除商品
    productBeginCheckout, //商结帐情况
    productsCheckoutProgress, //结帐情况
    productViewCart,
    productSetCheckoutOpt, //结帐情况
    productsPurchase, //商品展示
    getProductItemParams, //获取商品统计参数
    checkDelivey,
    specialSubSelectLens,
    basketFavoriteClick,
    ProuctLikeOrHotClick,
    gaWriteReviewClick,
    gaWriteCommentClick,
    gaUploadImgClick,
    referralClick,
    sizeGuideClick, //商品尺寸图点击监控
    gaTryOnUseEvent, //试戴功能使用时间
    gaCommonEvent, // ga 通用点击事件
    gaCustomerGuideSubmitEvent,
    gaIndexBannerClick, //首页广告位点击统计
    gaIndexSubBannerClick,
    gaIndexCategoryEntranceClick, //首页广告位点击统计
    gaIndexSmallADClick, //首页广告位点击统计
    gaLogisticsHelpClick, //物流数据埋点
    gaLogisticsHelpSearch, //物流数据埋点
    gaLogisticsQuery, //物流数据埋点
    gaLogisticsOrderTrack, //物流数据埋点
    gaLogisticsOrderListTrack, //物流数据埋点
    gaPaymentFailed, //支付失败ga统计
    gaIntoRecheckPage, //进入待支付页面
    gaRecheckPageCheckOutProcess, //待支付页面发起支付
    gaSelectLensBtnClick, //商品详情页选择镜片点击事件
    gaTopAdClick, //商城公告栏
    gaBubbleClick, // 商城气泡广告
    gaProductPageBottomAdClick, //动态专题页底部广告
    gaProductsPageInsertAdClick, //专题页产品列表嵌入广告
    gaMyAccountAdClick, //my account 页面顶部广告位
    gaNavSearchInputClick, // 导航栏搜索框点击
    gaContinuePayClick, // 再支付按钮点击
    gaLensPackageEvent, //镜片套餐与升级相关操作
    gaLoadedPromise, //GA加载完成promise
    gaContinueShopping, //继续购物
    gaReferral,
    gaStartALiveChat, //点击LiveChat
    addToCartGaConversion,
    beginCheckoutGaConversion,
    propagandaLink,
    clickEntryInDetailPage,
    addFreeBoxToCart,
    reAddFreeBoxToCart,
    getCodeOfFreeBox,
    timeOver,
    noFreeBoxShow,
    unusedCodeBox,
    gaGoSubstation,
    gaChooseCountry,
    gaCloseChooseModal,
    clickMyfitEntry,
    clickMyfitCanotFindSize,
    clickMyfitSubmitSuccess,
    clickMyfitSubmitError,
    clickMyfitEdit,
    clickMyfitDel,
    searchCotentClick,
};
