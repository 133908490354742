import { CUSTOMERSHOW } from "@/config/api";
import { VISITOR_COOKIE_NAME } from "@/config/constConf";
import { getCookieItem } from "@/lib/commonService";
import { isObject } from "../utils";
import { HandleJavaCommonFetch, HandlePhpFetch } from "@/src/utils/fetch";
const backendUrl = "front/user/browse/add";
export function getVistorId() {
    let cookieData;
    try {
        let jsonData = getCookieItem(VISITOR_COOKIE_NAME);

        if (jsonData) {
            cookieData = JSON.parse(jsonData);
        }
    } catch (e) {}
    const visitor_id = cookieData ? cookieData.visitor_id : "";
    return visitor_id;
}

/**
 *
 * @param price:价格,
 * @param colorId:颜色id,
 * @param browseUrl:url,
 * @param eventType:1点击sku 2添加购物车，3添加收藏 ，4点击商品item，5点击支付，7:进入支付页面，6支付成功，-10paypal资源加载失败监控，-11honey插件导致异常事件，-12paypal sdk加载失败之后跳转到新页面支付事件，
 * @param touristId：浏览者ID
 * @param filterItem：专题页或搜索的筛选父级项目。多个时英文逗号分隔
 * @param filterSubItem：专题页或搜索的筛选子级项目。多个时英文逗号分隔
 * @returns
 */

//点击sku
export const addProductsSkuClickEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    try {
        return HandleJavaCommonFetch.post(backendUrl, {
            ...param0,
            eventType: 1,
            browseUrl: window.location.pathname,
            touristId: visitor_id,
        });
    } catch (e: any) {
        console.log(e.message);
    }
};

//添加收藏
export const addFavouriteClickEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 3,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};

//点击商品item
export const addProductsToDetailEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 4,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};

export const getCustomerShow = (params: number | Record<string, any>) => {
    return HandlePhpFetch.get(CUSTOMERSHOW, {
        params: isObject(params) ? { limit: params } : params,
    });
};
