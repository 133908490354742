import {
    personalizeBatchEventLogin,
    personalizeEvent,
} from "@/src/models/personalize";
import { getSite } from "@/src/utils/configUtils";
import { ClickstreamAnalytics } from "@aws/clickstream-web";

// 错误处理包裹
function catchErrors(fn: Function) {
    return function (...args: any) {
        try {
            // @ts-ignore
            return fn.apply(this, args);
        } catch (error) {
            console.error(`Error occurred in function ${fn.name}:`, error);
        }
    };
}

class Acquisition {
    stadus: boolean = false;
    init(host: string) {
        if (getSite(host) === "au") {
            return;
        }
        this.onLoad();
    }
    // 初始化
    onLoad = catchErrors(() => {
        this.stadus = false;
        try {
            ClickstreamAnalytics.init({
                appId: "firmoo_clickstream",
                endpoint: "https://clickstream.firmooinc.com/collect",
            });
            this.stadus = true;
            // console.log("ClickstreamAnalytics加载成功");
        } catch (error) {
            this.stadus = false;
            console.log(error, "ClickstreamAnalytics加载失败");
        }
    });

    // 登录
    login = catchErrors((name: any, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            personalizeBatchEventLogin(id);
            ClickstreamAnalytics.setUserId(id);
        }
    });

    // 退出登录
    OutLogin = catchErrors((name: any, id: string | null) => {
        if (this.stadus) {
            // console.log(name, id);
            ClickstreamAnalytics.setUserId(id);
        }
    });

    // 收藏
    addWishlist = catchErrors((name: string, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            ClickstreamAnalytics.record({
                name: name,
                attributes: { item_id: id },
            });
        }
    });

    // 商品点击
    clickProduct = catchErrors((name: string, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            personalizeEvent(name, id);
            ClickstreamAnalytics.record({
                name: name,
                attributes: { item_id: id },
            });
        }
    });

    // 添加购物车
    addCart = catchErrors((name: string, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            personalizeEvent(name, id);
            ClickstreamAnalytics.record({
                name: name,
                attributes: { item_id: id },
            });
        }
    });

    // tryOn
    tryOn = catchErrors((name: string, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            personalizeEvent(name, id);
            ClickstreamAnalytics.record({
                name: name,
                attributes: { item_id: id },
            });
        }
    });

    // 创建订单
    createOrder = catchErrors((name: string, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            ClickstreamAnalytics.record({
                name: name,
                attributes: { item_id: id },
            });
        }
    });

    // 支付成功
    paidOrder = catchErrors((name: string, id: string) => {
        if (this.stadus) {
            // console.log(name, id);
            ClickstreamAnalytics.record({
                name: name,
                attributes: { item_id: id },
            });
        }
    });
}

let acquisition = new Acquisition();

export default acquisition;
