// 用户行为数据追踪
import Cookies from "js-cookie";
import { isLogin } from "./loginModel";
import { HandleJavaCommonFetch } from "@/src/utils/fetch";
import { getLocalItem, setLocalItem } from "@/lib/commonService";

// 事件类型，实际未登录只有clickProduct，tryOn，addCart这三个事件
type EventType =
    | "clickProduct" // 点击产品
    | "addCart" // 添加到购物车
    | "addWishlist" // 添加到收藏列表
    | "tryOn" // 尝试try-on
    | "createOrder" // 创建订单 order
    | "paidOrder"
    | string; // 已支付订单 pay

// 事件列表接口
interface EventList {
    eventType: EventType; // 事件类型
    itemId: string; // 关联的商品ID，格式为productId-colorId
    sentAt: string; // 事件发送时间
}
// 批量事件接口
interface PersonalizeBatchEvent {
    customersId: string; // 客户ID，注意这不是uid，而是登录后的customerId
    eventList: EventList[]; // 事件列表
    siteId: number; // 站点ID
}

/**
 * 自然来源，在登录后调用，补全用户行为
 * @param data PersonalizeBatchEvent
 */
export function personalizeBatchEvent(data: PersonalizeBatchEvent) {
    const params = { ...data, eventList: JSON.stringify(data.eventList) };
    return HandleJavaCommonFetch.post(`/front/personalize/batchEvent`, params);
}

// 单个事件接口
interface PersonalizePutEvent {
    userId: string; // 用户ID，这是uid
    siteId: number; // 站点ID
    eventType: EventType; // 事件类型
    itemId: string; // 关联的商品ID，格式为productId-colorId
}

/**
 * 带有uid的页面进入后调用，补全用户行为
 * @param data 见PersonalizePutEvent
 */
export function personalizePutEvent(data: PersonalizePutEvent) {
    return HandleJavaCommonFetch.post(`/front/personalize/putEvent`, data);
}

// 注册接口
interface PersonalizeRegister {
    siteId: number; // 站点ID
    userId?: string; // 用户ID
    registerMail: string;
}

/**
 * 带有uid，注册后调用，补全用户行为
 * @param data PersonalizeRegister
 * @returns
 */
export function personalizeRegister(email: string) {
    const userId = getUid();
    if (!userId) return;
    const params: PersonalizeRegister = {
        userId: "U" + userId,
        siteId: self.getSiteId(),
        registerMail: email || getLocalItem("email"), //注册邮箱
    };
    return HandleJavaCommonFetch.post(`/front/personalize/register`, params);
}

// 是否存在uid，不存在则从url中获取
export function getUid(cookie?: Record<string, string>) {
    if (cookie) {
        return cookie["uid"];
    } else {
        const uid =
            new URLSearchParams(window.location.search).get("uid") ||
            Cookies.get("uid");
        return uid;
    }
}

// 补全用户行为的事件
export function personalizeEvent(name: EventType, id: string) {
    const uid = getUid();
    const login = isLogin();
    // 未登录时，补全用户行为
    if (!login) {
        // 带有uid
        if (uid) {
            const params = {
                userId: "U" + uid,
                siteId: self.getSiteId(),
                eventType: name,
                itemId: id,
            };
            personalizePutEvent(params);
            return;
        } else {
            // 没有uid，存储到本地
            const batchEventList = getLocalItem("batchEventList") || [];

            const event = {
                eventType: name,
                itemId: id,
                sentAt: new Date().getTime(),
            };
            if (batchEventList.length >= 30) {
                batchEventList.shift(); // 移除最早的事件
            }
            batchEventList.push(event);
            setLocalItem("batchEventList", batchEventList);
            return;
        }
    }
}

// 批量补齐用户行为，登录时使用
export function personalizeBatchEventLogin(customersId: string) {
    const login = isLogin();
    const uid = getUid();
    if (!login || uid) return;
    const batchEventList = getLocalItem("batchEventList") || [];
    const params = {
        customersId: customersId, // 客户ID，注意这不是uid，而是登录后的customerId
        eventList: batchEventList, // 事件列表
        siteId: self.getSiteId(), // 站点ID
    };
    personalizeBatchEvent(params).then(() => {
        // 清除本地存储
        setLocalItem("batchEventList", []);
    });
}
