/**
 *
 * 获取用户行为
 * 精细化邮件营销，对用户浏览行为监控
 */

import { VISITOR_COOKIE_NAME } from "@/config/constConf";
import { getCookieItem } from "@/lib/commonService";
import { HandleJavaCommonFetch } from "@/src/utils/fetch";
import { addClickPayEventChannel } from "./CPUChannelData";
import { addClickPayEventUser } from "./CPUUserData";

const backendUrl = "front/user/browse/add";

function getVistorId() {
    let cookieData: Record<string, any>;
    try {
        const tempCookieData = getCookieItem(VISITOR_COOKIE_NAME);
        cookieData = JSON.parse(tempCookieData ?? "");
        return cookieData?.visitor_id ?? "";
    } catch (e) {}
}

/**
 *
 * @param price:价格,
 * @param colorId:颜色id,
 * @param browseUrl:url,
 * @param eventType:1点击sku 2添加购物车，3添加收藏 ，4点击商品item，5点击支付，7:进入支付页面，6支付成功，-10paypal资源加载失败监控，-11honey插件导致异常事件，-12paypal sdk加载失败之后跳转到新页面支付事件，
 * @param touristId：浏览者ID
 * @param filterItem：专题页或搜索的筛选父级项目。多个时英文逗号分隔
 * @param filterSubItem：专题页或搜索的筛选子级项目。多个时英文逗号分隔
 * @returns
 */

//点击sku
export const addProductsSkuClickEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 1,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//添加购物车
export const addToCartClickEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 2,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//添加收藏
export const addFavouriteClickEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 3,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//点击商品item
export const addProductsToDetailEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 4,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//点击支付
export const addClickPayEvent = (param0 = {}, cpu = {}) => {
    const visitor_id = getVistorId();
    addClickPayEventChannel(cpu);
    addClickPayEventUser(cpu);
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 5,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//支付成功
export const addPaySuccessEvent = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 6,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};

/**
 * paypal资源加载失败监控
 * @param {*} arg 如果paypal sdk加载失败 arg===undefined 如果是dataClientId加载失败arg==='-'
 */
export const paypalJsLoadFailEvent = (arg = "/") => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: arg,
        eventType: -10,
    });
};

// honey插件导致异常事件
export const honeyPluginEvent = () => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: -11,
    });
};

// paypal sdk加载失败之后跳转到新页面支付事件（法国站默认就是跳转到新页面支付，这种情况不会被统计）
export const openNewTabPayEvent = (monitorFlag = -12) => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: monitorFlag,
    });
};

export const enterCheckoutEvent = () => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: 7,
    });
};
