"use client";
import { usePathname } from "next/navigation";
import { useCallback, useMemo } from "react";
import criteoAd from "@/src/components/LoadAnalysis/analysis/criteo";
import { getCookieItem, getLocalItem } from "@/lib/commonService";
import { CURRENCY_COOKIE_NAME } from "@/config/constConf";
import { getEnv, getSite } from "@/src/utils/configUtils";
import {
    addToCartGaConversion,
    beginCheckoutGaConversion,
    gaCommonEvent,
    gaLoadedPromise,
    productAddToCar,
    productBeginCheckout,
    productsCheckoutProgress,
    unusedCodeBox,
} from "@/src/utils/gtagEvents";
import reddit from "@/src/components/LoadAnalysis/analysis/reddit";
import newTiktokPixel from "@/src/components/LoadAnalysis/analysis/newTiktokPixel";
import pintrkPixel from "@/src/components/LoadAnalysis/analysis/PintrkPixel";
import { useLensInfoStore } from "@/store/modules/lensInfo";
import {
    BASKET_GOODS,
    IProducts,
    getContents,
    getGAEventParamsForLens,
    getGAStaticPramsForOrder,
    getGApramasForChenckBtn,
    getIds,
    mapIdsAndPriceOfProducts,
} from "./utils";
import {
    addClickPayEvent,
    addPaySuccessEvent,
    addToCartClickEvent,
} from "@/src/models/emailData";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import { useOrderStore } from "@/store/modules/order";
import tiktokPixel from "@/src/components/LoadAnalysis/analysis/TiktokPixel";
import { BingPurchaseEvent } from "@/src/models/BingADEvents";
import { AddFlow, flowSpcice } from "@/src/utils";
import { useBasketStore } from "@/store/modules/basket";
import {
    eCommerce,
    includeGaPath,
    insertAllPage,
    insertByparams,
} from "@/src/components/LoadAnalysis/analysis/gaLib/ga";
import { useRateContext } from "@/src/provider/Rate/context";

const useAnalysis = () => {
    const path = usePathname();

    const lensInfoData = useLensInfoStore((state) => state.data);
    const order = useOrderStore((state) => state);
    const basket = useBasketStore((state) => state);
    const getPrice = useRateContext((state) => state.getPrice);

    // 谷歌分析
    const GoogleAnalytics = useCallback(
        (custom_params?: any, GALoadedAsyncCb?: () => void) => {
            insertAllPage().then((res) => {
                //使用异步调用
                GALoadedAsyncCb &&
                    typeof GALoadedAsyncCb === "function" &&
                    GALoadedAsyncCb();
                // console.log('custom_params', custom_params);
                if (includeGaPath(path) && custom_params) {
                    insertByparams(custom_params);
                } else if (custom_params) {
                    insertByparams(custom_params);
                } else {
                    insertByparams();
                }
            });
        },
        [path],
    );

    // 电子商务
    const gaECommerce = useCallback((custom_params: any) => {
        eCommerce(custom_params);
    }, []);

    // fb
    const facebookPixel = useCallback(
        ({ trackPage, trackPageContent }: any) => {
            if (!window.fbq) {
                let retries = 0;
                const maxRetries = 20;

                const fbqCheckInterval = setInterval(() => {
                    if (window.fbq) {
                        clearInterval(fbqCheckInterval);
                        window.fbq("track", trackPage, trackPageContent);
                        console.log("facebookPixel 加载成功");
                        console.log(trackPage, trackPageContent);
                    } else if (retries >= maxRetries) {
                        clearInterval(fbqCheckInterval);
                        console.log("facebookPixel 加载失败");
                    }
                    retries++;
                }, 1000);
            } else {
                window.fbq("track", trackPage, trackPageContent);
                console.log(trackPage, trackPageContent);
            }
        },
        [],
    );

    /**
     * 用于注册完成事件
     */
    const completeRegistrationEvent = useCallback(() => {
        pintrkPixel.Signup();
        newTiktokPixel.CompleteRegistration();
        // fb
        self.fbq &&
            self.fbq("track", "CompleteRegistration", {
                value: 1.0,
                currency: getCookieItem(CURRENCY_COOKIE_NAME),
            });
    }, []);

    /**
     * 用于镜片流程中购物车加购事件，其他地方的快捷加购，单独写，移动端需要区分新老流程
     */
    const addToCartEvent = useCallback(
        (isNew?: boolean) => {
            const { product_price, color_id, product, products_id } =
                lensInfoData;

            const sendData = getGAEventParamsForLens(lensInfoData);
            // TODO 需要开发到购物车部分后实装
            // const { total } = calcTotalPrice();
            const total = 10000000;
            const host = self.location.host;

            // 区分下单的新老流程
            const customEventKeyword = isNew
                ? "add to cart new2"
                : "add to cart old";
            gaCommonEvent(customEventKeyword);
            const currency = getCookieItem(CURRENCY_COOKIE_NAME);

            //添加GA统计
            gaLoadedPromise().then(() => {
                productAddToCar({
                    value: total,
                    currency,
                    items: [sendData],
                });
                addToCartGaConversion(total, host);
            });

            newTiktokPixel.AddToCart(
                {
                    contents: [
                        {
                            quantity: 1,
                            content_id: `${sendData.id}`,
                            price: total,
                            content_name: sendData.name,
                        },
                    ],
                    content_type: "product",
                    value: total,
                    currency: self.process.env.CURRENCY,
                },
                sendData.id,
            );

            reddit.AddToCart({
                currency,
                itemCount: 1,
                value: total,
            });

            pintrkPixel.AddToCart(
                {
                    value: total,
                    order_quantity: 1,
                    currency,
                },
                sendData.id,
            );
            addToCartClickEvent({
                colorId: color_id,
                price: total,
            });
            criteoAd.addToCart({
                id: color_id,
                price: product_price,
                quantity: 1,
            });
        },
        [lensInfoData],
    );

    /**
     * 购物车checkoutBtn点击事件和checkout页面的事件
     */
    const initiateCheckoutEvent = useCallback(
        (type: "checkoutBtn" | "checkoutPage", res?: any) => {
            // 当在checkout页面时，res为必须传入，调用请求函数为getCommitBasket
            if (type === "checkoutPage") {
                const basket = res;
                const obj = mapIdsAndPriceOfProducts(
                    basket.glass,
                    basket.total.price,
                );
                const ids =
                    obj.ids.length > 1
                        ? obj.ids
                        : obj.ids.length === 1
                          ? obj.ids[0]
                          : "";
                tiktokPixel.Checkout();
                facebookPixel({
                    trackPage: "InitiateCheckout",
                    trackPageContent: "",
                });
                GoogleAnalytics({
                    ecomm_prodid: ids, //产品ID//填充
                    ecomm_pagetype: "checkout",
                    ecomm_totalvalue: obj.price, //产品价格//填充
                });
            } else {
                const host = self.location.host;
                const { totalPrice, commitProducts, select_basket_arr } =
                    basket;
                const CURRENCY = getEnv("CURRENCY", host);
                const sendData = getGApramasForChenckBtn(commitProducts);
                sendData.length &&
                    gaLoadedPromise().then(() => {
                        productBeginCheckout({
                            value: totalPrice,
                            currency: CURRENCY,
                            items: sendData,
                        });
                        beginCheckoutGaConversion(getPrice(totalPrice), host);
                    });
                const contents: any[] = [];
                sendData.forEach((element) => {
                    contents.push({
                        price: element.price,
                        quantity: element.quantity,
                        content_id: `${element.item_id}`,
                        content_type: "product",
                    });
                });
                newTiktokPixel.InitiateCheckout(
                    {
                        currency: CURRENCY, // Mandatory
                        value: totalPrice, // Mandatory
                        contents: contents,
                    },
                    select_basket_arr.join(","),
                );
            }
        },
        [GoogleAnalytics, basket, facebookPixel, getPrice],
    );

    /**
     * 用于checkout页面的下单事件
     */
    const placeAnOrderEvent = useCallback(
        (products_info: IProducts[]) => {
            const currency = getCookieItem(CURRENCY_COOKIE_NAME);

            const { color_id, products_id } = lensInfoData;
            const { paymentId, total, products, freeBoxAddOnId, coupon } =
                order;
            if (color_id && products_id) {
                acquisition.createOrder(
                    "createOrder",
                    `${products_id}-${color_id}`,
                );
            }

            const sendData = getGAStaticPramsForOrder(products_info, paymentId);
            //ga统计 增加支付方式 event_label
            gaLoadedPromise().then(() => {
                sendData.items.length &&
                    productsCheckoutProgress({
                        ...sendData,
                        currency: currency,
                        value: total,
                    });

                if (
                    products.find((it: any) => it.addons_id === freeBoxAddOnId)
                ) {
                    unusedCodeBox(coupon);
                }
            });
            const contents: any[] = [];
            sendData.items.forEach((element: any) => {
                contents.push({
                    quantity: element.quantity,
                    content_id: element.id,
                    price: element.price,
                    content_name: element.name,
                });
            });
            newTiktokPixel.PlaceAnOrder({
                content_type: "product",
                contents: contents,
                currency: currency,
                value: total,
            });
            const productQuantity = products_info.reduce(
                (accumulator, current) =>
                    accumulator +
                    (typeof current.quantity === "number"
                        ? current.quantity
                        : 1),
                0,
            );
            addClickPayEvent({}, { productQuantity });
        },
        [lensInfoData, order],
    );

    /**
     * 暂时没看到使用这个事件的地方
     */
    const addPaymentInfoEvent = useCallback(() => {}, []);

    /**
     * 用于checkoutSuccess页面的支付成功事件
     * 在调用getOrderInfos接口后调用
     */
    const completePaymentEvent = useCallback(
        (res: any, order_id: number) => {
            const host = self.location.host;

            const CURRENCY = getEnv("CURRENCY", host);
            const GA_CONVERSITION_ID_1 = getEnv("GA_CONVERSITION_ID_1", host);
            const GA_CONVERSITION_ID_2 = getEnv("GA_CONVERSITION_ID_2", host);

            AddFlow.checkSuccess(res);
            flowSpcice.checkSuccess(res);
            res.orders_products.forEach((item: any) => {
                if (item[0].products_id && item[0].color_id) {
                    acquisition.paidOrder(
                        "paidOrder ",
                        `${item[0].products_id}-${item[0].color_id}`,
                    );
                }
            });
            let ids = res.orders_products.map(
                (item: any) => item[0].products_id,
            );
            ids = ids.length > 1 ? ids : ids.length === 1 ? ids[0] : "";

            const { contents, ttk_contents } = getContents(res);

            const totalPrice = res.orders_total[0].value;

            let shippingPrice: any = "0.00";
            res.orders_total.forEach((priceItem: any) => {
                if (priceItem.class === "ot_shipping") {
                    shippingPrice = priceItem.value;
                }
            });

            const total =
                res.orders_total.find(
                    (item: any) => item.class === "ot_total",
                ) || {};

            const shippingMethods = ["Standard shipping", "Express shipping"];

            const shipping =
                res.orders_total.find((item: any) =>
                    shippingMethods.includes(item.title),
                ) || {};

            //Pinterest事件跟踪代码
            pintrkPixel.InitiateCheckout(
                {
                    value: totalPrice,
                    order_quantity: 1,
                    currency: getCookieItem(CURRENCY_COOKIE_NAME),
                },
                `${order_id}`,
            );
            const info = {
                content_ids: ids, //产品id 数组
                content_type: "product",
                contents: contents, //产品id 数组
                value: ids, //产品价格 不带货币单位
                currency: res.extra.currency,
            };

            tiktokPixel.Purchase(info);
            newTiktokPixel.CompletePayment(
                {
                    contents: ttk_contents, //产品id 数组
                    value: parseFloat(totalPrice), //产品价格 不带货币单位
                    currency: res.extra.currency,
                },
                `${order_id}`,
            );

            reddit.Purchase({
                currency: res.extra.currency,
                itemCount: res.orders_products.length,
                transactionId: order_id,
                value: totalPrice,
            });
            facebookPixel({
                trackPage: "Purchase",
                trackPageContent: {
                    content_ids: getIds(ids, res.orders_products), //产品id 数组
                    content_type: "product",
                    value: totalPrice, //产品价格 不带货币单位
                    currency: res.extra.currency,
                },
            });

            criteoAd.trackTransaction(order_id, res.orders_products);

            //判断是否在从rerecheck 成功
            const isRechekout = getLocalItem("oceanRepay");
            const custom_params = {
                order_id: order_id,
                ecomm_prodid: ids, //产品ID
                ecomm_pagetype: "purchase",
                ecomm_products: res.orders_products,
                totalPrice: totalPrice,
                shippingValue: shipping.value,
                ecomm_totalvalue: totalPrice, //产品价格//填充
                event_label: isRechekout ? "waiting_payment" : "direct",
            };
            //ga增强转化代码
            self.enhanced_conversion_data = {
                email: res.customers_email_address,
            };
            GoogleAnalytics(custom_params, () => {
                //等ga注入成功后，再调用ga相关api
                gaECommerce(custom_params);
                // let win: any = window;
                getSite(host) === "mx" &&
                    window.gtag("set", "user_data", {
                        email: res.customers_email_address,
                    });
                const gtagList = [GA_CONVERSITION_ID_1, GA_CONVERSITION_ID_2];

                gtagList.forEach((element: string) => {
                    element &&
                        window.gtag("event", "conversion", {
                            //函数已经定义过了。
                            send_to: element,
                            value: totalPrice, //具体订单金额，此处为变量
                            currency: CURRENCY, //货币单位
                            transaction_id: order_id, //具体订单号，此处为变量
                        });
                });

                window.snaptr &&
                    window.snaptr("track", "PURCHASE", {
                        currency: "GBP",
                        price: totalPrice,
                        item_ids: ids,
                    });
            });

            addPaySuccessEvent();

            BingPurchaseEvent({
                total: totalPrice,
                productId: custom_params.order_id,
            });
            const coupon_code = res.coupon_code;
            const shippingDisPrice = totalPrice - shippingPrice;
            const shippingPriceFixed = (
                shippingDisPrice > 0 ? shippingDisPrice : parseFloat(totalPrice)
            ).toFixed(2);
            const timer = setInterval(() => {
                if ("AWIN" in window) {
                    clearInterval(timer);
                    let site_id = getSite(host);
                    let awin_id: any = "";
                    if (site_id === "fr") {
                        awin_id = 26597;
                    }
                    if (site_id === "de") {
                        awin_id = 22043;
                    }

                    if (getLocalItem("source") === "awin" && awin_id) {
                        const pixelImg = document.createElement("img");
                        pixelImg.src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${awin_id}&amount=${shippingPriceFixed}&ch=aw&cr=EUR&parts=SALE:${shippingPriceFixed}&ref=${order_id}&vc=${coupon_code}`;
                        pixelImg.onload = () => {
                            window.AWIN.Tracking.Sale = {
                                amount: shippingPriceFixed,
                                channel: "aw",
                                currency: "EUR",
                                orderRef: order_id,
                                parts: `SALE:${shippingPriceFixed}`,
                                voucher: coupon_code,
                                test: "0",
                            };
                            window.AWIN.Tracking.run();
                            localStorage.removeItem("source");
                        };
                        document.body.appendChild(pixelImg);
                    }
                }
                if ("ITCVRQ" in window) {
                    clearInterval(timer);
                    if (getLocalItem("source") === "webgains") {
                        window.ITCVRQ("set", "cvr", {
                            value: shippingPriceFixed,
                            currency: window.WG.CUR,
                            language: window.WG.LAN,
                            eventId: window.WG.EVTID,
                            orderReference: order_id,
                            comment: "",
                            multiple: "",
                            checksum: "",
                            items: "",
                            customerId: "",
                            voucherId: coupon_code,
                            location:
                                document.location.href ||
                                "https://firmoo.co.uk",
                        });
                        window.ITCVRQ("conversion");
                        localStorage.removeItem("source");
                    }
                }
            }, 500);
        },
        [GoogleAnalytics, facebookPixel, gaECommerce],
    );

    /**
     * commonAnalysis 定义用于分析的公共事件
     * @function ViewContent - 商品详情页的浏览事件
     * @function CompleteRegistration - 用于注册完成事件
     * @function AddToCart - 用于镜片流程中购物车加购事件，其他地方的快捷加购，单独写
     * @function InitiateCheckout - 开始结账，这个事件有两个地方，一个在checkoutBtn，一个在checkout页面
     * @function PlaceAnOrder - 下单，pay securely now按钮
     * @function AddPaymentInfo - 添加支付信息完成，新tt
     * @function CompletePayment - 支付成功，新tt（Purchase - fb，reddit和老tt使用这个）
     */
    const commonAnalysis = useMemo(() => {
        return {
            CompleteRegistration: completeRegistrationEvent,
            AddToCart: addToCartEvent,
            InitiateCheckout: initiateCheckoutEvent,
            PlaceAnOrder: placeAnOrderEvent,
            AddPaymentInfo: addPaymentInfoEvent,
            CompletePayment: completePaymentEvent,
        };
    }, [
        addPaymentInfoEvent,
        addToCartEvent,
        completePaymentEvent,
        completeRegistrationEvent,
        initiateCheckoutEvent,
        placeAnOrderEvent,
    ]);

    return {
        commonAnalysis,
        GoogleAnalytics,
        gaECommerce,
        facebookPixel,
    };
};

export default useAnalysis;
