import { NextRequest } from "next/server";

export enum HttpMethods {
    GET = "GET",
    POST = "POST",
    PATCH = "PATCH",
    DELETE = "DELETE",
    PUT = "PUT",
}

/**
 * 获取域名
 * @param request
 */
export function getDomain(request: NextRequest) {
    return request.headers.get("x-forwarded-host") ?? new URL(request.url).host;
}

/**
 * 根据域名匹配环境变量
 * @param host
 * @param envName
 */
export function getEnv(envName: string, host?: string) {
    try {
        // 设置默认值
        let defaultValue;
        if (envName === "LANGUAGE") defaultValue = "en";
        else if (envName === "CURRENCY") defaultValue = "USD";

        // 客户端取环境变量
        if (typeof window !== "undefined" && window.process) {
            return window.process.env[envName] || defaultValue;
        }

        // 没有域名 或者 环境变量中有这个值
        if (!host || process.env[envName]) {
            return process.env[envName] ?? defaultValue;
        }
        const envCode = getSiteId(host);
        return (
            globalThis.APOLLO_DATA.envConfig[`${envCode}`][envName] ||
            defaultValue
        );
    } catch (e) {
        console.log(e);
    }
}

/**
 * 获取站点id
 * @param host
 */
export function getSiteId(host: string) {
    return typeof window !== "undefined" && window.getSiteId
        ? window.getSiteId()
        : globalThis.APOLLO_DATA.domainSiteIdConfig[host];
}

/**
 * 获取站点简写
 * @param host 域名
 * @returns
 */
export function getSite(host: string): string {
    return typeof window !== "undefined" && window.getSite
        ? window.getSite()
        : globalThis.APOLLO_DATA.domainSiteConfig[host];
}

export function isNewSite() {
    if (typeof window === "undefined") {
        return !process.env.SITE;
    } else {
        return window.newSite;
    }
}

export function getWebpUrl(src: string): string {
    if (!src) return src;
    const dotPosition = src.lastIndexOf(".");
    return dotPosition > 0 ? `${src.slice(0, dotPosition)}.webp` : src;
}

export default getWebpUrl;

// 获取站点配置
export function getSiteConfiguration(key: string, host: string) {
    try {
        if (typeof window !== "undefined") {
            return window.getSiteConfiguration(key);
        }

        const siteId = getSiteId(host);
        return globalThis.APOLLO_DATA.confConfig[siteId][key];
    } catch (e) {
        console.error("getSiteConfiguration error", key, host);
    }
}
