import { getLocalItem, setLocalItem } from "@/lib/commonService";
import { flowadd } from "../models/loginModel";
const getVistorId = () => {
    let visitor_id;
    if (!getLocalItem("uniqueString")) {
        const timestamp = new Date().getTime(); // 获取当前时间戳
        const randomNum = Math.floor(Math.random() * 10000); // 生成一个四位随机数
        const uniqueString = `${timestamp}${randomNum}`; // 将时间戳和随机数连接起来
        visitor_id = uniqueString;
        setLocalItem("uniqueString", uniqueString);
    } else {
        visitor_id = getLocalItem("uniqueString");
    }
    return visitor_id;
};
class FlowSpcice {
    url = "";
    flow = "";
    data = "";
    // 注意判断逻辑是否正确
    init(first: boolean, flow: string) {
        if (first) {
            this.first(flow);
        } else {
            this.middle(flow);
        }
    }
    //第一次
    first(flow: string) {
        if (flow) {
            let param = {
                eventType: 1,
                paramValue: flow,
                touristId: getVistorId(),
            };
            flowadd(param).then((res) => {
                setLocalItem("flow1", flow);
            });
        }
    }
    //中间事件
    middle(data: string) {
        if (data) {
            let param = {
                eventType: 3,
                paramValue: data,
                touristId: getVistorId(),
            };
            flowadd(param).then((res) => {
                setLocalItem("flow1", data);
            });
        }
    }
    ProductClick(id: string | number) {
        const flow1 = getLocalItem("flow1");
        if (flow1) {
            let param = {
                eventType: 5,
                paramValue: flow1,
                productsId: id,
                touristId: getVistorId(),
            };
            flowadd(param);
        }
    }
    register(id: string | number) {
        const flow1 = getLocalItem("flow1");
        if (flow1) {
            let param = {
                eventType: 2,
                paramValue: flow1,
                customersId: id,
                touristId: getVistorId(),
            };
            flowadd(param);
        }
    }
    checkSuccess(res: any) {
        const flow1 = getLocalItem("flow1");
        if (flow1) {
            let param = {
                eventType: 4,
                paramValue: flow1,
                touristId: getVistorId(),
                ordersOid: res.extra.orders_oid,
                price: res.extra.order_total_out_shipping,
            };
            flowadd(param);
        }
    }
}

let flowSpcice = new FlowSpcice();

export default flowSpcice;
