"use client";
import { Rater } from "@/store/modules/rate";
import { useContext } from "react";
import { useStore } from "zustand";
import { RateContext } from "./Provider";

export function useRateContext<T>(selector: (state: Rater) => T): T {
    const store = useContext(RateContext);
    if (!store) throw new Error("Missing RateContext.Provider in the tree");
    return useStore(store, selector);
}

export { RateProvider } from "./Provider";
