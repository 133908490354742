import { getCookieItem, getLocalItem} from "@/lib/commonService";
import { requestTiktokApi } from "@/src/models/analysis";
import { getVistorId } from "@/src/models/customerShow";
import { getSite } from "@/src/utils/configUtils";

// 站点代码，老项目的it不应该分开，这里合在一起
const site_code: Record<string, string[] | string> = {
    us: "C87FTFT8U2K62KB9JST0",
    uk: ["CICFEJBC77U0O25FFAPG", "CGNR473C77U7F650IR1G"],
    fr: "CHHL0JRC77UC4FMFO3RG",
    de: "CHLD8MJC77U7QBTM05DG",
    mx: "CJ1ONKRC77U75407KPVG",
    it: "CGHVF6BC77UFNT4NU91G",
    au: "CO36NKJC77U479UCNQN0",
};

// 新tt
class TiktokPixel {
    id: string | string[] = "";
    host: string = "";
    // 通过域名初始化
    init(host: string) {
        // 根据域名获取对应站点代码
        this.id = site_code[getSite(host)];
        this.host = host;
        if (typeof this.id === "string") {
            this.onLoad(this.id);
        }
        if (Array.isArray(this.id)) {
            for (let element of this.id) {
                this.onLoad(element);
            }
        }
    }

    // 加载js脚本
    onLoad(id: string) {
        if (!id) {
            return;
        }
        // tk脚本
        const head = document.getElementsByTagName("HEAD").item(0);
        const label = document.createElement("script");
        label.type = "text/javascript";
        label.innerHTML = `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            ttq.load('${id}');
            ttq.page();
            }(window, document, 'ttq');`;
        head && head.appendChild(label);
    }

    eventForEach(event: string, info: any, event_id?: string) {
        if (window.ttq && this.id) {
            if (typeof this.id === "string") {
                event_id
                    ? window.ttq
                          .instance(this.id)
                          .track(event, info, { event_id: event_id })
                    : window.ttq.instance(this.id).track(event, info);
                this.tiktokApiRequest({
                    pixelCode: this.id,
                    event,
                    properties: info,
                    event_id,
                });
                console.log(`ttq ${event}`, info);
            }
            if (Array.isArray(this.id)) {
                for (let index = 0; index < this.id.length; index++) {
                    const element = this.id[index];
                    window.ttq.instance(element).track(event, info);
                    console.log(`ttq ${event} ${index + 1}`, info);
                }
            }
        }
    }
    tiktokApiRequest({
        pixelCode,
        event,
        properties,
        event_id,
    }: {
        pixelCode: string;
        event: string;
        properties: any;
        event_id?: string;
    }) {
        if (!event_id || getSite(this.host) != "us") {
            return;
        }
        let params = {
            event_source_id: pixelCode,
            event: event,
            event_id,
            user: {
                ttclid: getCookieItem("ttclid"),
                ttp: getCookieItem("_ttp"),
                user_agent: navigator.userAgent,
                external_id: getLocalItem("customers_id") || "",
                email: getLocalItem("customers_email_address") || "",
            },
            page: {
                url: location.href,
                referrer: document.referrer,
            },
            properties: properties,
        };
        getLocalItem("customers_email_address") &&
            window.ttq.identify({
                email: getLocalItem("customers_email_address"), // string. The email of the customer if available.
                external_id: getLocalItem("customers_id"), // A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
            });
        requestTiktokApi(params);
    }
    //所有页面（排除shopping_cart和checkout页面）添加网页浏览事件
    ViewContent(info = {}, event_id: string | number) {
        if (
            window.location.href.indexOf("shopping_cart") > -1 ||
            window.location.href.indexOf("checkout") > 0
        ) {
            return;
        }
        ttqLoadedPromise().then(() => {
            this.eventForEach(
                "ViewContent",
                info,
                `${event_id}-${getVistorId()}`,
            );
        });
    }

    //注册完成
    CompleteRegistration(info = {}) {
        if (this.id) {
            ttqLoadedPromise().then(() => {
                this.eventForEach("CompleteRegistration", info);
            });
        }
    }

    //加入购物车
    AddToCart(info = {}, event_id: string) {
        if (this.id) {
            ttqLoadedPromise().then(() => {
                this.eventForEach(
                    "AddToCart",
                    info,
                    `${event_id}-${getVistorId()}`,
                );
            });
        }
    }

    //开始结账
    InitiateCheckout(info = {}, event_id: string) {
        if (this.id) {
            ttqLoadedPromise().then(() => {
                this.eventForEach(
                    "AddToCart",
                    info,
                    `${event_id}-${getVistorId()}`,
                );
            });
        }
    }

    //pay securely now按钮
    PlaceAnOrder(info = {}) {
        if (this.id) {
            ttqLoadedPromise().then(() => {
                this.eventForEach("PlaceAnOrder", info);
            });
        }
    }

    //添加支付信息完成
    AddPaymentInfo(info = {}) {
        if (this.id) {
            ttqLoadedPromise().then(() => {
                this.eventForEach("AddPaymentInfo", info);
            });
        }
    }

    //支付成功
    CompletePayment(info = {}, event_id: string) {
        if (this.id) {
            ttqLoadedPromise().then(() => {
                this.eventForEach("CompletePayment", info, event_id);
            });
        }
    }
}

//检测GA脚本是否加载完成
function ttqLoadedPromise() {
    return new Promise((resolve) => {
        const checkGA = () => {
            if (window && window.ttq) {
                resolve(true);
            } else {
                setTimeout(checkGA, 50);
            }
        };
        checkGA();
    });
}

// 新tiktok，和老的区分
const newTiktokPixel = new TiktokPixel();

export default newTiktokPixel;
