export interface ILensInfo {
    isLoading: boolean;
    data: {
        is_favorite: boolean;
        isRless: boolean;
        product: {
            products_model: string;
            products_have_sunmode: any;
        };
        product_price: Record<string, any>;
        lensOptions: any[];
        products_id?: number;
        color_id?: number;
    };
    error: string;
    glassestype: string;
    datavalue: Record<string, any>;
    color_canvas_img: string;
}

export type State = ILensInfo;

export type ILensInfonAction = State & Action;

export type Action = {
    // getLens: (id: string) => Promise<void>;
    favoriteProduct: () => void;
    changesetlens: (conditions: Record<string, any>) => void;
    changeColorImg: (color_canvas_img: string) => void;
    changetype(types: string): void;
    setLensOptions: (data: any[]) => void;
};

export const initialState: State = {
    isLoading: false,
    data: {
        is_favorite: false,
        isRless: false,
        product: {
            products_model: "",
            products_have_sunmode: undefined,
        },
        product_price: {},
        lensOptions: [],
        products_id: undefined,
        color_id: undefined,
    },
    error: "",
    glassestype: "",
    datavalue: {},
    color_canvas_img: "",
};
