const masterCategoriesIdInsertGlassesType: Record<string, any>[] = [
    {
        key: ["1", "0"],
        value: "eyeglasses",
    },
    {
        key: ["3", "4", "9"],
        value: "goggles",
    },
    {
        key: ["6", "7"],
        value: "sunglasses",
    },
];
function getProductDetialHref(
    productId: number | undefined,
    master_categories_id: string = "0",
): string {
    // 如果没匹配上就默认是eyeglasses
    const value =
        masterCategoriesIdInsertGlassesType.filter((item) =>
            item.key.includes(master_categories_id),
        )[0]?.value ?? "eyeglasses";
    return `/${value}-p-${productId}.html`;
}

export default getProductDetialHref;
