"use client";
import { useContext } from "react";
import { CommonContext, CommonDataProps } from "./Provider";

// 使用上下文，只能在客户端使用，旨在服务端初始化数据，后在客户端使用，如需在客户端做缓存，可以使用仿照Rate的例子
export function useCommonContext(): CommonDataProps {
    const store = useContext(CommonContext);
    if (!store) throw new Error("Missing useCommonContext.Provider in the tree");
    return store;
}

export { CommonProvider } from "./Provider";
