class TiktokPixel {
    id = "";

    setId(id) {
        this.id = id;
    }

    AddToCart(info) {
        window.ttq.instance(this.id).track("AddToCart", info);
    }

    Checkout() {
        window.ttq.instance(this.id).track("Checkout");
    }

    Purchase(info) {
        window.ttq.instance(this.id).track("Purchase", info);
    }
}

const tiktokPixel = new TiktokPixel();

// 没有配置的站点不执行代码
const keyList = Object.getOwnPropertyNames(Object.getPrototypeOf(tiktokPixel));
for (const key of keyList) {
    if (key.charCodeAt(0) >= 65 && key.charCodeAt(0) <= 90) {
        const oldFunc = tiktokPixel[key];
        const newFunc = function () {
            if (this.id) {
                oldFunc.apply(tiktokPixel, arguments);
            }
        };
        tiktokPixel[key] = newFunc.bind(tiktokPixel);
    }
}

export default tiktokPixel;
