import { getSession, getTouristId, setSession } from "@/lib/commonService";
import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";
import {
    BASKET_ADD_COMPONENT_API,
    BASKET_COMMIT_API,
    BASKET_CREATE_API,
    BASKET_CREATE_COMPONENT_API,
    BASKET_DELETE_API,
    BASKET_NUM,
    BASKET_SHARE,
    DELETE_OFFLINE,
    GET_BASKET_COMMIT_API,
} from "@/config/api";
import { isLogin } from "./loginModel";
import { WINDOW_SESSION_KEYS } from "@/config/constConf";
import _ from "lodash";
const { BASKET_NUM_SESSION } = WINDOW_SESSION_KEYS;

export const bindTouristId = async () => {
    return getTouristId().then(async (id) => {
        await HandlePhpFetch.patch(`basket/tourist?tourist_id=${id}`)
            .then((res) => {})
            .catch((err) => {});
    });
};

//创建购物车数据
export const create = async (data: Record<string, any>) => {
    let touristId = undefined;
    if (!isLogin()) {
        await getTouristId().then((id) => {
            touristId = id;
        });
    }
    data.tourist_id = touristId || "";

    return HandlePhpFetch.post(BASKET_CREATE_API, data);
};

//购物车数量更新
export const update = async (basketId: number, quantity: number) => {
    let touristId = undefined;
    if (!isLogin()) {
        await getTouristId().then((id) => {
            touristId = id;
        });
    }
    const tourist_id = touristId || "";

    return HandlePhpFetch.patch(`basket/${basketId}`, { quantity, tourist_id });
};

//获取购物车数量
export const getBascketNum = async (cookie?: any) => {
    //读取缓存
    const sessionNum = getSession(BASKET_NUM_SESSION);
    if (getSession(BASKET_NUM_SESSION)) {
        return new Promise((res, rej) => {
            res(sessionNum);
        }).catch((e) => console.log(e));
    }
    //获取接口
    let touristId = undefined;
    const jwt = cookie ? cookie.jwt : "";
    if (!isLogin()) {
        if (!cookie) {
            //合并登陆后和登陆前的购物车
            await getTouristId().then((id) => {
                touristId = id;
            });
        } else {
        }
    }
    const obj = jwt ? { headers: { "jwt-auth": jwt } } : null;
    //请求地址
    try {
        const data = await HandlePhpFetch.get(
            `${BASKET_NUM}?tourist_id=${touristId}`,
        );
        //缓存购物车数量
        setSession(BASKET_NUM_SESSION, data);
        return data;
    } catch (e: any) {
        console.log(e);
    }
};

export const getCommitBasket = async () => {
    let touristId = undefined;
    if (!isLogin()) {
        await getTouristId().then((id) => {
            touristId = id;
        });
    }

    return HandlePhpFetch.get(
        `${GET_BASKET_COMMIT_API}`,
        touristId
            ? {
                  tourist_id: touristId,
              }
            : {},
    );
};

export const show = async (cookie?: any) => {
    let touristId = undefined;
    if (!isLogin()) {
        if (!cookie) {
            //合并登陆后和登陆前的购物车
            await getTouristId().then((id) => {
                touristId = id;
            });
        } else {
        }
    }
    return HandlePhpFetch.get(`/basket${touristId ? "/" + touristId : ""}`)
        .then((data) => {
            //缓存购物车数量
            !isLogin() &&
                ((data) => {
                    setTimeout(() => {
                        const { virtual, addOn, glass } = data.online
                            ? data.online
                            : data;
                        const offlineProducts = data.offline
                            ? [
                                  ...data.offline.virtual,
                                  ...data.offline.addOn,
                                  ...data.offline.glass,
                              ]
                            : [];
                        let countData = [...virtual, ...addOn, ...glass];

                        let total = _.isEmpty(countData)
                            ? 0
                            : countData.reduce((m, n, i) =>
                                  typeof m === "object"
                                      ? m.quantity + n.quantity
                                      : m + n.quantity,
                              );
                        total =
                            typeof total === "object" ? total.quantity : total;

                        let offTotal = _.isEmpty(offlineProducts)
                            ? 0
                            : offlineProducts.reduce((m, n, i) =>
                                  typeof m === "object"
                                      ? m.quantity + n.quantity
                                      : m + n.quantity,
                              );
                        offTotal =
                            typeof offTotal === "object"
                                ? offTotal.quantity
                                : offTotal;

                        setSession(BASKET_NUM_SESSION, total + offTotal);
                    }, 0);
                })(data);
            return data;
        })
        .catch((e) => {
            console.log(e);
        });
};

export const commitBasket = async (ids: string) => {
    const payload = {
        basket_ids: ids,
        tourist_id: undefined,
    };
    if (!isLogin()) {
        await getTouristId().then((id: any) => {
            payload.tourist_id = id;
        });
    }

    return HandlePhpFetch.post(`${BASKET_COMMIT_API}`, payload);
};

//客服购物车分享提交
export const commitBasketShare = async ({
    basket_ids = "",
    is_delete = false,
}) => {
    const payload = {
        basket_ids,
        is_delete,
        tourist_id: undefined,
    };
    if (!isLogin()) {
        await getTouristId().then((id: any) => {
            payload.tourist_id = id;
        });
    }

    return HandlePhpFetch.post(`${BASKET_SHARE}`, payload);
};

export const remove = async (id: number) => {
    let touristId = undefined;
    if (!isLogin()) {
        await getTouristId().then((id) => {
            touristId = id;
        });
    }

    return HandlePhpFetch.delete(
        `${BASKET_DELETE_API}/${id}/${touristId || ""}`,
    );
};

export const addComponent = async (basket_id: number) => {
    let touristId = undefined;
    if (!isLogin()) {
        //合并登陆后和登陆前的购物车
        await getTouristId().then((id) => {
            touristId = id;
        });
    }
    return HandlePhpFetch.post(
        `${BASKET_ADD_COMPONENT_API}${basket_id}/${touristId || ""}`,
    );
};

// 2022年11月25日 16:22:37 圣诞节送礼品，在选择部分商品支付的时候需要判断一下是否满足规则
export const checkSelectProducts = async (basket_ids: string) => {
    try {
        await HandlePhpFetch.post("/basket/checkoutBasketData", {
            basket_ids,
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const createComponent = async (id: number, type: string, qty = 1) => {
    const payload: Record<string, any> = {
        product_id: id,
        product_type: type,
        quantity: qty,
    };
    if (!isLogin()) {
        await getTouristId().then((id) => {
            payload.tourist_id = id;
        });
    }
    return HandlePhpFetch.post(BASKET_CREATE_COMPONENT_API, payload);
};

// 删除下线产品
export const deleteOffline = async () => {
    let touristId = undefined;
    if (!isLogin()) {
        await getTouristId().then((id) => {
            touristId = id;
        });
    }

    return HandlePhpFetch.delete(`${DELETE_OFFLINE}/${touristId || ""}`);
};
