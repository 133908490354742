/**
 *
 * 获取cpu数据
 * 精细化邮件营销，对用户浏览行为监控
 */

import { HandleJavaCommonFetch, HandlePhpFetch } from "../utils/fetch";
import { getVistorId } from "./customerShow";
import {
    getCookieItem,
    getLocalItem,
    setCookieItem,
} from "@/lib/commonService";

const backendUrl = "cpu/history/channel/access/add";

/**
 *
 * @param price:价格,
 * @param colorId:颜色id,
 * @param browseUrl:url,
 * @param eventType:1点击sku 2添加购物车，3添加收藏
 * @param touristId：浏览者ID
 * @param filterItem：专题页或搜索的筛选父级项目。多个时英文逗号分隔
 * @param filterSubItem：专题页或搜索的筛选子级项目。多个时英文逗号分隔
 * @param productQuantity :参数值(用于匹配对应的推广来源)
 * @param paramValue :购买的商品总数量
 * @returns
 */

//点击sku
export const addProductsSkuClickEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        eventType: 1,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//添加购物车
export const addToCartClickEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        eventType: 2,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//添加收藏
export const addFavouriteClickEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        eventType: 3,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//点击商品item
export const addProductsToDetailEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        eventType: 4,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//点击支付
export const addClickPayEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        eventType: 5,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//支付成功
export const addPaySuccessEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        eventType: 6,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};

/**
 * paypal资源加载失败监控
 * @param {*} arg 如果paypal sdk加载失败 arg===undefined 如果是dataClientId加载失败arg==='-'
 */
export const paypalJsLoadFailEvent = (arg = "/") => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: arg,
        eventType: -10,
    });
};

// honey插件导致异常事件
export const honeyPluginEvent = () => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: -11,
    });
};

// paypal sdk加载失败之后跳转到新页面支付事件（法国站默认就是跳转到新页面支付，这种情况不会被统计）
export const openNewTabPayEvent = (monitorFlag = -12) => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: monitorFlag,
    });
};

// 进入支付页面
export const enterCheckoutEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        browseUrl: "/",
        eventType: 7,
    });
};

// 注册成功用户-用于渠道运营汇总
export const registerEventChannel = (param0 = {}) => {
    let paramValue = getLocalItem("flow");
    if (!paramValue) {
        return;
    }
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        paramValue,
        browseUrl: "/",
        eventType: 8,
    });
};

export const handleGid = ({
    userId,
    customerId,
    email,
}: {
    userId: string;
    customerId?: string;
    email: string;
}) => {
    console.log({ userId, customerId, email });
    if (!userId || !email) {
        return;
    }

    const cookie_uid = getCookieItem("user_id");
    const cookie_cid = getCookieItem("firmoo_cid");
    const cookie_ga = getCookieItem("_ga");
    const cookie_gid = cookie_ga ? cookie_ga.substring(6) : cookie_ga;

    if (cookie_uid === `${userId}` && cookie_cid === `${customerId}`) {
        //无数据更改
        return;
    }

    //储存用户id
    userId &&
        setCookieItem("user_id", userId, {
            path: "/",
            expires: new Date(new Date().getTime() + 8760 * 3600 * 1000),
        });
    customerId &&
        setCookieItem("firmoo_cid", customerId, {
            path: "/",
            expires: new Date(new Date().getTime() + 8760 * 3600 * 1000),
        });

    userGidRelation({ gid: cookie_gid, userId, customerId, email });
};

// GID 埋点
export const userGidRelation = ({
    gid,
    userId,
    customerId,
    email,
}: {
    gid?: string;
    userId: string;
    customerId?: string;
    email: string;
}) => {
    return HandlePhpFetch.post("front/user/gid/relation", {
        gid,
        userId,
        customerId,
        email,
    });
};
