import { getVistorId } from "@/src/models/customerShow";
import { getSite } from "@/src/utils/configUtils";
import { getLocalItem } from "@/lib/commonService";
//检测Pintrk是否加载完成
function pintrkLoadedPromise() {
    let timer: any = null;
    return new Promise((rev, rej) => {
        if (window && window.pintrk) {
            rev(true);
        } else {
            timer = setInterval(() => {
                if (window && window.pintrk) {
                    rev(true);
                    clearInterval(timer);
                }
            }, 50);
        }
    });
}

let site_code = {
    it: "2613968646937",
    us: "2612352787049",
    au: "2612994101194",
};

type PPSite = keyof typeof site_code;

class PintrkPixel {
    id = "";
    domain = "";

    init(domain: string) {
        const site = getSite(domain) as PPSite;
        const id = site_code[site];
        if (!id) return;
        this.domain = domain;
        this.id = id;
        this.onLoad(this.id);
    }
    onLoad(ele: string) {
        if (!ele) {
            return;
        }
        const customers_email_address = getLocalItem("customers_email_address");
        const head = document.getElementsByTagName("HEAD").item(0);
        const label = document.createElement("script");
        label.type = "text/javascript";
        label.innerHTML = `
          !function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
              n=window.pintrk;n.queue=[],n.version="3.0";var
              t=document.createElement("script");t.async=!0,t.src=e;var
              r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '${ele}', {em: '<${
                customers_email_address ? customers_email_address : ""
            }>'});
            pintrk('page');`;

        const labelNoScript = document.createElement("noscript");
        const labelImg = document.createElement("img");
        labelImg.height = 1;
        labelImg.width = 1;
        labelImg.style.display = "none";
        labelImg.alt = "";
        labelImg.src = `https://ct.pinterest.com/v3/?event=init&tid=${ele}&pd[em]=<${customers_email_address}>&noscript=1`;
        labelNoScript.appendChild(labelImg);
        head && head.appendChild(label);
        head && head.appendChild(labelNoScript);
    }

    //加入购物车
    AddToCart(info = {}, event_id: string | number) {
        if (this.id) {
            pintrkLoadedPromise().then(() => {
                info = {
                    event_id: `${event_id}-${getVistorId()}`,
                    ...info,
                };
                console.log("pintrk addtocart", info);
                window.pintrk("track", "addtocart", info);
            });
        }
    }
    //开始结账
    InitiateCheckout(info = {}, event_id: string | number) {
        if (this.id) {
            info = {
                event_id: `${event_id}-${getVistorId()}`,
                ...info,
            };
            console.log("pintrk checkout", info);
            window.pintrk("track", "checkout", info);
        }
    }

    Signup() {
        if (this.id) {
            let info = {
                event_id: `signup-${getVistorId()}`,
            };
            console.log("pintrk signup", info);
            window.pintrk("track", "signup", info);
        }
    }
}

const pintrkPixel = new PintrkPixel();

export default pintrkPixel;
