const diff: Record<string, any> = {
    "api/javaSearch/": process.env.JAVA_SEARCH_URL,
    "api/javaCommon/": process.env.JAVA_COMMON_URL,
    "api/javaOpencv/": process.env.JAVA_OPENCV_URL,
    "api/javaPacy/": process.env.JAVA_PACY_URL,
    "api/javaReview/": process.env.JAVA_REVIEW_URL,
    "api/javaUsual/": process.env.JAVA_USUAL_URL,
    "api/remoteapi/": process.env.API_URL,
    "api/upload/": process.env.API_URL,
    "api/opencvUpload/opencv_upload": process.env.JAVA_OPENCV_URL,
};
export function replaceUrlByDiffrentFetch(url: string, replaceUrl: string) {
    const replaceDomain = diff[replaceUrl] || "";
    return formatUrl(`${replaceDomain}${url.replace(replaceUrl, "")}`);
}

//规范url格式: 平等的替换每一根不符合要求的双斜线
export function formatUrl(url: string) {
    return url.replace(/([^:]\/)\/+/g, "$1");
}

// 规范fetch类的url前缀
export function getPreUrlByApiTag(
    url: string,
    apiTag: string,
    realDomainUrl?: string,
) {
    if (!/^\/?api\/\w+/.test(apiTag)) {
        return url;
    }
    let tempTag = apiTag.replace(/\/?(api)/, "$1");

    return formatUrl(
        (typeof window === "undefined"
            ? (realDomainUrl || diff[tempTag]) + "/"
            : window.location.origin + "/" + tempTag) + url,
    );
}
/**
 * 设置服务器头信息
 * @param headers 头信息对象
 * @returns 更新后的头信息对象
 */
export function setServerHeader(headers?: Record<string, string>) {
    if (typeof window === "undefined" && headers) {
        if (!headers["language"]) {
            headers["language"] = headers["Accept-Language"] = headers["hLang"];
            delete headers["hLang"];
        }
        if (!headers["site-id"]) {
            headers["site-id"] = headers["hSiteId"];
            delete headers["hSiteId"];
        }
    }
    return headers;
}

// 规范化get请求后的斜杠，方式多走一次服务端导致阻塞
export function formatHTTPGetUrl(
    url: string,
    params: { [key: string]: any } = {},
) {
    const query = new URLSearchParams(params).toString();
    return (query ? `${url}?${query}` : url).replace(/\/(\?.)/, "$1"); // 匹配类似于https://firmoo-app-test.proxy.fmootech.cn/api/remoteapi/basket/count/?tourist_id=kt791nc6l3的形式，替换...count/?xxx=xxx => ...count?xxx=xxx
}
