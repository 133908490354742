import { getEnv } from "@/src/utils/configUtils";

export function IMG_HOST() {
    // 获取环境变量的值
    const host = process.env.NEXT_PUBLIC_IMG_HOST || getEnv("IMG_HOST");

    if (host && host.endsWith("/")) {
        return host.slice(0, -1);
    }

    return host;
}

export function S3_URL() {
    return process.env.NEXT_PUBLIC_S3_URL || getEnv("S3_URL");
}

export function BASE_URL() {
    return process.env.NEXT_PUBLIC_BASE_URL;
}

const BASE_BASE_URL = BASE_URL() + "remoteapi/";
export const BASE_JAVA_URL = BASE_URL() + "remotejava/";
export const BASE_RIVEW_URL = BASE_URL() + "remote_review/";
export const BASE_EMAIL_URL = BASE_URL() + "remote_java_common/";
export const BASE_JAVA_USUAL_URL = BASE_URL() + "remote_java_usual/";
export const BASE_JAVA_PACY_URL = BASE_URL() + "remote_pacy/";
export const FACEBOOK_REDIRECT = BASE_URL() + "facebooklogin"; // facebook回传地址
export const INVITE = BASE_URL() + "invite";
export const NAV_CLASSIFY = BASE_URL() + "classify";

// TODO font_end_env 文件里边往window上挂载的时候改了名字 暂时不使用getEnv替换
// export const JAVA_REVIEW = getEnv()
//     typeof window !== "undefined"
//         ? window.process.env.JAVA_REVIEW
//         : process.env.JAVA_REVIEW_URL;

// common
const CURRENCY = "currencies";
const CUSTOMER_ID = "user/customer-id";
const USER_COUNTRY = "user_country";
// USER 模块
const USER = "user";

const REGISTER_API = "user/";
const LOGIN_API = REGISTER_API + "login";
const LOGOUT_API = "logout";
const FORGET_PWD = "logout";
const ZAN = USER + "/zan";
const SWIPER_INFO = "http://localhost:3000/static/mock.json";
// 登录（前/后）监控
export const LOGIN_MONITOR_BEFORE_API = "front/login/monitor/before";
export const LOGIN_MONITOR_AFTER_API = "/front/login/monitor/after";

export const REVIEW_COMMENT_API = USER + "/comment";
export const FACEBOOKLOGIN = LOGIN_API + "/facebook";
export const POST_REVIEW = USER + "/comment/";
export const NEWUSERCODE = "common/coupon";

// 获取用户在2D试戴时上传图片的接口
export const USER_PHOTO = "/front/cv/user/photo";
// 用户在2D试戴时上传图片的接口
export const USER_PHOTO_UPLOAD = "/opencv_upload/front/cv/upload/detect/eye";
// 用户再2D试戴时上传瞳孔信息的接口
export const USER_PHOTO_SET_EYE_POSITION = "/front/cv/eye/photo/add";

// PRODUCT 模块
export const PRODUCTS = "products/";
export const PRODUCT_COMMENT_API = PRODUCTS + "comment/all/";
export const COMMENT_DETAIL_API = PRODUCTS + "comment/show/";
export const COMMET_PREVIEW_API = PRODUCTS + "comment/";
export const PRODUCT_REVIEW_RECOMMEND = PRODUCTS + "reviews/recommend";
export const LIKE_PRODUCTS = PRODUCTS + "recommend/";
export const HOT_PRODUCTS = PRODUCTS + "recommend/hot/";
export const INDEX_PRODUCTS = PRODUCTS + "index";
export const INDEX_PRODUCTS_SERVER = PRODUCTS + "index/server";
export const ADD_ONS = "/products/addons";
export const ADD_ONS_CONF = "/products/addonsCategory";
export const VIRTUALS = "/products/virtuals";
export const FORGETPASSWORD = "user/forget-password";
export const SENDFORGETPASSWORD = "/auth/password/email";
export const RESET_PASSWORD = "/auth/password/reset";
export const CHECKCOMMENT = "user/comment/check/";
export const PREVIEW = "preview";

//basket
export const BASKET_PRODUCT_API = "/basket/product";
export const BASKET_DELETE_API = "/basket";
export const BASKET_COMMIT_API = "/basket/commitBasket";
export const GET_BASKET_COMMIT_API = "/basket/getCommitBasket";
export const BASKET_SHARE = "/basket/basketShare";
export const BASKET_SHARE_CONFIRM = "/basket/confirmBasketShare";
export const BASKET_SHARE_CLICK = "/basket/clickNumberUpdate";
export const BASKET_CREATE_API = "/basket";
export const BASKET_CREATE_COMPONENT_API = "/basket/component";
export const BASKET_ADD_COMPONENT_API = "/basket/";
export const BASKET_INFO_BY_ID = "/basket/edit";

export const BASKET_NUM = "/basket/count/";
export const DELETE_OFFLINE = "basket/offline";

//checkout
export const PAYPAY_SUCCESS_CALLBACK = "/payment/paypal/return";
export const OCEANPAY_SUCCESS_CALLBACK = "/payment/ocean/return";
export const POST_COUPON = "/promotion/coupon";
export const ORDER_STATUS = "/status";

export {
    BASE_BASE_URL,
    LOGIN_API,
    LOGOUT_API,
    REGISTER_API,
    FORGET_PWD,
    SWIPER_INFO,
    ZAN,
    CURRENCY,
    CUSTOMER_ID,
    USER_COUNTRY,
};

//首页 模块
export const AD = "ad"; //广告

//checkoutSuccess问卷调查

export const QUESTIONAIRE = "orders/investigation";

export const EMAIL_SUB = "common/email/sub";
//博猪 模块
export const CUSTOMERSHOW = "common/customer/show"; //广告
export const CUSTOMERSTATICSHOW = "/common/customer/static/show"; //买家秀第一板块
export const CUSTOMERSHOWFOOTER = CUSTOMERSHOW + "/footer"; //买家秀第二板块

//HELP 模块
export const HELPURL = "user/help/"; //帮助中心默认接口
export const HELP_LEFT = "left"; //帮助中心默认接口
export const HELP_LIST = "label"; //帮助中心标签文章信息接口
export const HELP_INFO = "article"; //帮助中心标签文章信息接口
export const HELP_FUL = "advice"; //帮助中心提交建议接口
export const USE = "zan"; //帮助中心提交建议接口
export const HELP_ACTIVI_LIST = "category_and_article_title"; //帮助中心 获取分类及分类下的文章标题
export const ADVISE = "advise"; //用户帮助中心建议接口
export const ARTICLES = "articles"; //帮助中心 热门问题列表
export const CATEGORY_CLICK = "category/clicks/"; //帮助中心 分类点击记录

//个人中心模块
export const ORDERS = "orders/"; //获取订单列表
export const UNPAID_ORDERS_NUM = "orders/getUnpaidOrderNum"; //获取客户待支付订单数量
export const ORDERS_SERVICE = "orders/service"; //自助服务
export const ORDERS_SERVICE_DELETE = "orders/service/products/"; //自助服务
export const TRACK = "track/"; //获取订单物流详情
export const PAYMENT_ORDERS = "payment/orders/"; //获取支付订单信息
export const ORDER_LOGS = "/logs"; //创建订单日志
export const ADDRESS = "address/"; //获取|新增|修改 地址
export const CHECKOUT_ZIP_CODE = "user/address/checkoutZipCode"; //验证偏远地区邮编
export const SERVICESHOW = ORDERS + "service/show/"; //获取是否提交过退换货信息
export const CHANGE = ORDERS + "change"; //取消|退|换|修改 货物
export const COUNTRIES = ADDRESS + "countries"; //国家
export const TRACKINFO = "order/track/info/"; //快递单号查询
export const ORDERCODE = "/common/order/number/"; //订单号查询订单ID
export const RESETPASSWORD = "resetPassword"; //修改密码接口
export const SUBSCRIPTION = "subscription"; //获取个人订阅
export const SUBS = "subs";
export const UNSUBSCRIBE = "unsubscribe"; //取消订阅
export const FAVORITE = "favorite"; //取消|获取收藏
export const NOTIFY = "follow"; //取消|获取订阅
export const PHOTO = "photo"; //获取|删除 用户照片
export const PRESCRIPTION = "prescription"; //帮助中心提交建议接口
export const WALLET = "wallet"; //申请提现|钱包
export const COUPON = WALLET + "/coupon"; //钱包兑换优惠卷
export const ENCODE = "union/encode"; //用于用户中心联盟页，根据当前登录用户获取其邀请码
export const DECODE = "union/decode"; //用于解密为用户的UID
export const PROMOTION_COUPON = "/promotion/coupon/exclusive"; //新增获取用户专属优惠券接口
export const SHARE_COUPON_STATISTICS = "/promotion/coupon/share"; //新增获取用户专属优惠券接口
export const DECODE_COUPON_CODE = "common/decode"; //解码优惠券code
export const ORDERS_REFUND = "refund"; //获取退款信息
export const ORDERS_REORDER = "reorder"; //重新下单
export const CHAGNE_COUPON_CODE = "user/coupon"; // 修改个人专属优惠券
export const LOYALTY_DOLLARS_LOG = "promotion/getLoyaltyDollarsLog"; // 获取佣金最新佣金轮播信息

// PAGE 专题
export const PAGE = "page/";
export const PAGE_PRODUCTS = PAGE + "products/";

// 镜片页
export const LENS = PRODUCTS + "lens/";
export const CLIPS = PRODUCTS + "clips/";
export const RX = REGISTER_API + "prescription/"; //增 | 删 | 改 | 查 处方
export const LENSPACKAGE = "lens/package"; //套餐获取
export const SCENE_LENSPACKAGE = "lens/scene-package"; //场景化套餐获取
export const SCENE_PACKAGE_CHECK = "lens/scene-package-check"; //套餐获取
export const SCENE_PACKAGE_BLUE = "lens/recommend"; //套餐获取
export const LENS_OPTIONS = "lens/options"; //镜片价格数据获取

//搜索
export const SEARCHAPI = "search";

// 根据jwt获取token

export const JWT_TOKEN = BASE_URL() + USER + "/customer-token";

// ad scheme
export const SCHEME = "stateless/visitor/scheme";

export const VISITOR = "user/visitor/id";
//记录浏览者访问详情
export const RECORD_VISITOR = "user/visitor";
export const SCHEME_LOGS = SCHEME + "/logs";
//通告栏广告位
export const AD_TOP = "ad-top";

//专题
export const AJAX_ZT = "v1Firmoo/ajax_zt.php";

export const GET_QUESTION = "/common/question";

//用户语言偏好 接口
export const USER_LIKE_LAN_URL = "user/locale";

//用户地区获取接口
export const USER_POSITION = "user/position";

//获取用户收藏商品列表，用于列表页面的商品收藏
export const USER_FAVORITE_CURRENT = "user/favorite/current";

//跟踪用户接口
export const USER_RECORD = "user/record";

//生成webp 图片几口
export const BUILD_WEBP_URL = "upload/format";
//联系我们
export const CONTACT_US_URL = "common/contact";
//设置用户偏好
export const USER_PREFERENCE = "user/preference";
//bing 广告id

//圣诞报名邮件确认
export const CHRISTMAS_CHECK = "active/christmas/email-check";
//圣诞活动报名
export const CHRISTMAS_APPLY = "active/christmas/apply";
//圣诞活动报名
export const CHRISTMAS_STATUS = "active/christmas/status";
//圣诞重发邮件
export const CHRISTMAS_RESEND = "active/christmas/re-sendmail";
//圣诞中奖列表
export const CHRISTMAS_WINNER = "active/christmas/winner-list";

// 页面服务端渲染速度和接口速度监控
export const PERFORMACE_MONITOR = "/front/request/monitor/add";

// 性能采集
export const WEBSITE_PERFORMANCE = "/webSite/performance/data";
