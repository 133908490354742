import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { OrderAction, initialState } from "./initialState";
import _ from "lodash";

export const useOrderStore = create<OrderAction>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                setBascketNum(totalNum: number) {
                    set({ totalNum });
                },

                setProducts(data) {
                    let total = _.isEmpty(data.onlineProducts)
                        ? 0
                        : data.onlineProducts.reduce((m, n, i) =>
                              typeof m === "object"
                                  ? m.quantity + n.quantity
                                  : m + n.quantity,
                          );
                    total = typeof total === "object" ? total.quantity : total;

                    let offTotal = _.isEmpty(data.offlineProducts)
                        ? 0
                        : data.offlineProducts.reduce((m, n, i) =>
                              typeof m === "object"
                                  ? m.quantity + n.quantity
                                  : m + n.quantity,
                          );
                    offTotal =
                        typeof offTotal === "object"
                            ? offTotal.quantity
                            : offTotal;
                    set({
                        products: data.onlineProducts.reverse(),
                        offlineProducts: data.offlineProducts.reverse(),
                        totalNum: total + offTotal,
                        offTotal,
                        onlianeTotal: total,
                    });
                },
                // 移除产品
                removeProduct(basket_id: number) {
                    const products = get().products.filter(
                        (item) => item.basket_id !== basket_id,
                    );
                    const offlineProducts = get().offlineProducts.filter(
                        (item) => item.basket_id !== basket_id,
                    );
                    set({
                        products,
                        offlineProducts,
                    });
                },
                // 设置当前页面
                setPage(page: string) {
                    set({
                        page,
                    });
                },
            }),
            {
                name: "order-storage",
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
        {
            name: "order-store",
        },
    ),
);
