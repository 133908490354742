/**
 *
 * 添加Criteo广告跟踪代码
 * 以便Criteo再营销平台进行用户行为跟踪并进行针对性的商品及广告投放。
 *
 */
import { VISITOR_COOKIE_NAME } from "@/config/constConf";
import { getCookieItem } from "@/lib/commonService";
import { getEnv } from "@/src/utils/configUtils";

class CriteoAd {
    account = [];
    deviceType = "";
    visitorId = 0;
    criteo_q;

    //判断criteo资源的加载情况，所有的事件都必须在criteo加载完全后运行
    criteoLoadedPromise() {
        let timer = null;
        let that = this;
        return new Promise((rev, rej) => {
            if (!this.account[0] && !this.account[1]) {
                console.log("没有CRITEO_ID");
                rej();
                return;
            }
            if (window && window.criteo_q) {
                if (!that.criteo_q) {
                    that.criteo_q = window.criteo_q || [];
                }

                rev(true);
            } else {
                timer = setInterval(() => {
                    if (window && window.criteo_q) {
                        rev(true);
                        clearInterval(timer);
                    }
                }, 50);
            }
        });
    }

    //加载criteo资源
    init() {
        this.account = [getEnv("CRITEO_ID"), getEnv("CRITEO_ID_2")];
        if (!this.account[0] && !this.account[1]) {
            return;
        }
        //获取设备类型
        this.deviceType = /iPad/.test(navigator.userAgent)
            ? "t"
            : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                    navigator.userAgent,
                )
              ? "m"
              : "d";
        let cookie = getCookieItem(VISITOR_COOKIE_NAME);
        try {
            cookie = JSON.parse(cookie);
        } catch (e) {}

        //获取访问者ID，确认用户的唯一性
        this.visitorId =
            cookie !== undefined ? cookie.visitor_id : 0 + "@firmoo.com";

        this.account.map((arg, index) => {
            if (arg) {
                let script = document.createElement("script");
                script.src = `//dynamic.criteo.com/js/ld/ld.js?a=${arg}`;
                document.querySelector("head").appendChild(script);
                script.onload = () => {
                    window.criteo_q = window.criteo_q || [];
                    this.criteoLoadedPromise().then(() => {
                        console.log("criteo viewPage", arg);
                        window.criteo_q.push(
                            { event: "setAccount", account: arg },
                            {
                                event: "setEmail",
                                email: `${this.visitorId}`,
                                hash_method: "none",
                            },
                            { event: "setSiteType", type: this.deviceType },
                            { event: "viewPage" },
                        );
                    });
                };
            }
            return;
        });
    }

    viewHome() {
        this.criteoLoadedPromise().then(() => {
            this.account.map((arg) => {
                console.log("criteo viewHome", arg);
                if (arg) {
                    window.criteo_q.push(
                        { event: "setAccount", account: arg },
                        {
                            event: "setEmail",
                            email: `${this.visitorId}`,
                            hash_method: "none",
                        },
                        { event: "setSiteType", type: this.deviceType },
                        { event: "viewHome" },
                    );
                }
                return;
            });
        });
    }

    viewList({ data }) {
        this.criteoLoadedPromise().then(() => {
            let item = [];

            data.forEach((arg) => {
                if (
                    arg.products_color_info &&
                    arg.products_color_info.length > 0
                ) {
                    arg.products_color_info.forEach((ele) => {
                        item.push(ele.color_id);
                    });
                }
            });

            this.account.map((arg) => {
                if (arg) {
                    console.log(`criteo viewList ${arg}`, {
                        event: "viewList",
                        item: item.join(","),
                        category: window.location.pathname,
                        keywords: window.location.search,
                    });
                    window.criteo_q.push(
                        { event: "setAccount", account: arg },
                        {
                            event: "setEmail",
                            email: `${this.visitorId}`,
                            hash_method: "none",
                        },
                        { event: "setSiteType", type: this.deviceType },
                        {
                            event: "viewList",
                            item: item.join(","),
                            category: window.location.pathname,
                            keywords: window.location.search,
                        },
                    );
                }
                return;
            });
        });
    }

    viewItem(color_id) {
        this.criteoLoadedPromise().then(() => {
            this.account.map((arg) => {
                if (arg) {
                    window.criteo_q.push(
                        { event: "setAccount", account: arg },
                        {
                            event: "setEmail",
                            email: `${this.visitorId}`,
                            hash_method: "none",
                        },
                        { event: "setSiteType", type: this.deviceType },
                        { event: "viewItem", item: color_id },
                    );
                    console.log(`criteo viewItem ${arg}`, color_id);
                }
            });
        });
    }

    addToCart({ id, price, quantity }) {
        this.criteoLoadedPromise().then(() => {
            this.account.map((arg) => {
                if (arg) {
                    window.criteo_q.push(
                        { event: "setAccount", account: arg },
                        {
                            event: "setEmail",
                            email: `${this.visitorId}`,
                            hash_method: "none",
                        },
                        { event: "setSiteType", type: this.deviceType },
                        { event: "addToCart", item: [{ id, price, quantity }] },
                    );
                    console.log("criteo addToCart:" + arg, [
                        { id, price, quantity },
                    ]);
                }
                return;
            });
        });
    }

    viewBasket(glass = []) {
        this.criteoLoadedPromise().then(() => {
            let item = [];
            glass.forEach((element) => {
                item.push({
                    id: element.color_id,
                    price: element.price,
                    quantity: element.quantity,
                });
            });

            this.account.map((arg) => {
                if (arg) {
                    window.criteo_q.push(
                        { event: "setAccount", account: arg },
                        {
                            event: "setEmail",
                            email: `${this.visitorId}`,
                            hash_method: "none",
                        },
                        { event: "setSiteType", type: this.deviceType },
                        { event: "viewBasket", item: item },
                    );

                    console.log("criteo viewBasket:" + arg, item);
                }
                return;
            });
        });
    }

    trackTransaction(transactionId, orders_products = []) {
        this.criteoLoadedPromise().then(() => {
            let item = [];
            orders_products.forEach((element) => {
                item.push({
                    id: element[0].color_id,
                    price: element[0].total_price,
                    quantity: element[0].products_quantity,
                });
            });

            this.account.map((arg) => {
                if (arg) {
                    window.criteo_q.push(
                        { event: "setAccount", account: arg },
                        {
                            event: "setEmail",
                            email: `${this.visitorId}`,
                            hash_method: "none",
                        },
                        { event: "setSiteType", type: this.deviceType },
                        { event: "trackTransaction", id: transactionId, item },
                    );

                    console.log("criteo trackTransaction:" + arg, {
                        id: transactionId,
                        item,
                    });
                }
                return;
            });
        });
    }
}

let criteoAd = new CriteoAd();
export default criteoAd;
