/**
 * 这个函数对一部分ssr渲染错误进行包装，方便在_error.jsx组件显示出具体的错误
 * @param {string} url
 * @param {Promise} prom
 */
const wrapper = async (
    url: string,
    prom: Promise<Record<"news" | "hot", any>>,
) => {
    return prom
        .then((res) => {
            return res;
        })
        .catch((err) => {
            const now = new Date();
            return Promise.reject({
                apiError: true,
                datetime: now.toLocaleString() + "  " + now.getTime(),
                message:
                    typeof err === "string"
                        ? err
                        : err
                          ? err.message || "Error"
                          : "Error",
                url,
                res: err,
            });
        });
};

export default wrapper;
