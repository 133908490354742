import { isServer } from "@/lib/commonService";
import { getSite } from "@/src/utils/configUtils";

interface InitProps {
    country?: string;
    region?: string;
    domain: string;
}

class Reddit {
    isInit: boolean = false;
    init(props: InitProps) {
        let { country = "", region = "", domain } = props;
        this.isInit = getSite(domain) === "us";
        if (!isServer && getSite(domain) === "us") {
            const head: any = document.querySelector("head");
            const script = document.createElement("script");
            let option: any = {
                optOut: false,
                useDecimalCurrencyValues: true,
                aaid: "",
                email: "",
                externalId: "",
                idfa: "",
            };
            if (country === "US" && region === "CA") {
                option = {
                    dpm: "LDU",
                    dpcc: "US",
                    dprc: "US-CA",
                    ...option,
                };
            }
            script.type = "text/javascript";
            script.innerHTML = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
        rdt('init','t2_ilpji6g7', ${JSON.stringify(option)});
          rdt('track', 'PageVisit');`;
            head.appendChild(script);
            this.isInit = true;
        }
    }

    getRdt() {
        return new Promise((resolve) => {
            let count = 15;
            const intervalId = setInterval(() => {
                if (!isServer && window.rdt) {
                    clearInterval(intervalId);
                    resolve(window.rdt);
                } else {
                    if (--count < 0) {
                        clearInterval(intervalId);
                        resolve(() => {});
                    }
                }
            }, 1000);
        });
    }

    // 1 购买
    Purchase(info = {}) {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit Purchase", info);
        this.getRdt().then((rdt: any) => rdt("track", "Purchase", info));
    }

    // 2 加购
    AddToCart(info = {}) {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit AddToCart", info);
        this.getRdt().then((rdt: any) => rdt("track", "AddToCart", info));
    }

    // 3 收藏
    AddToWishlist(info = {}) {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit AddToWishlist", info);
        this.getRdt().then((rdt: any) => rdt("track", "AddToWishlist", info));
    }

    // // 4 提交信息
    // static Lead(info = {}) {
    //   this.getRdt().then(rdt => rdt('track', 'Lead', info));
    // }

    // 5 注册
    SignUp() {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit SignUp");
        this.getRdt().then((rdt: any) => rdt("track", "SignUp"));
    }

    // 6 页面访问
    PageVisit() {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit PageVisit");
        this.getRdt().then((rdt: any) => rdt("track", "PageVisit"));
    }

    // 7 详情页
    ViewContent() {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit ViewContent");
        this.getRdt().then((rdt: any) => rdt("track", "ViewContent"));
    }

    // 8 搜索
    Search() {
        if (!this.isInit) {
            return;
        }
        console.log("Reddit Search");
        this.getRdt().then((rdt: any) => rdt("track", "Search"));
    }

    // 9 自定义转化事件
    Custom(evt: any, info = {}) {
        if (!this.isInit) {
            return;
        }
        this.getRdt().then((rdt: any) => rdt("track", evt, info));
    }
}

const reddit = new Reddit();

export default reddit;
