import { BASKET_GOODS } from "@/src/hooks/useAnalysis/utils";

interface State {
    isLoading: boolean;
    commitProducts: BASKET_GOODS[];
    totalNum: number;
    totalPrice: number;
    select_basket_arr: number[];
    errors: string;
}

export type Action = {
    getCommitBasketList: () => Promise<void>;
    selectBasketItems: (select_basket_arr: any[]) => void;
};
export type IBasket = State & Action;

export const initialState: State = {
    isLoading: false,
    commitProducts: [],
    totalNum: 0,
    totalPrice: 0,
    select_basket_arr: [],
    errors: "",
};
