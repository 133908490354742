import { CURRENCY_COOKIE_NAME } from "@/config/constConf";
import { getCookieItem } from "@/lib/commonService";

function bingLoadPromise() {
    let timer: any = null;
    return new Promise((rev, rej) => {
        if (window && window.gtag) {
            rev(true);
        } else {
            timer = setInterval(() => {
                if (window && window.gtag) {
                    rev(true);
                    clearInterval(timer);
                }
            }, 50);
        }
    });
}
//bing purchase envet
function BingPurchaseEvent(params: any = {}) {
    bingLoadPromise().then(() => {
        window.uetq = window.uetq || [];
        const { total, productId } = params;
        window.uetq.push("event", "purchase", {
            gv: total,
            revenue_value: total,
            currency: getCookieItem(CURRENCY_COOKIE_NAME),
            ecomm_prodid: productId,
            ecomm_pagetype: "purchase",
            event_value: total,
        });
    });
}

export { BingPurchaseEvent };
