enum ErrorCode {
    Success = 0,
    NotFound = 100404,
    AuthenticationFailed = 203002,
    RemoteAreaShipping = 200163,
    RemoteAreaUndeliverable = 200164,
    OtherRequestIssues = 203003,
    PaymentLimitExceeded = 1001000,
}

export async function handleErrors(response: Response): Promise<any> {
    if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
    }

    const responseData = await response.json();
    if (responseData.error !== ErrorCode.Success) {
        switch (responseData.error) {
            case ErrorCode.AuthenticationFailed:
            case ErrorCode.OtherRequestIssues:
                throw new Error(responseData.message);
            case ErrorCode.PaymentLimitExceeded:
                return { code: ErrorCode.PaymentLimitExceeded };
            case ErrorCode.RemoteAreaShipping:
                return { code: 1 };
            case ErrorCode.RemoteAreaUndeliverable:
                return { code: 2 };
            case ErrorCode.NotFound:
                return { code: 404 };
            default:
                throw new Error(responseData.message);
        }
    }
    return responseData.data;
}
