"use client";
import { Rate, RateStore, createRateStore } from "@/store/modules/rate";
import { createContext, useRef } from "react";
export const RateContext = createContext<RateStore | null>(null);

interface RateProviderProps extends React.PropsWithChildren<Rate> {}

export function RateProvider({ children, ...props }: RateProviderProps) {
    const storeRef = useRef<RateStore>();
    if (!storeRef.current) {
        storeRef.current = createRateStore(props);
    }
    return (
        <RateContext.Provider value={storeRef.current}>
            {children}
        </RateContext.Provider>
    );
}
