"use client";
import React, {
    createContext,
    useState,
    ReactNode,
    useCallback,
    useMemo,
} from "react";
import { FmToast, IToastProps } from "@/ui-component";

export type ToastContextType = {
    showToast: (
        message: ReactNode,
        type?: "info" | "loading",
        seconds?: number,
        onChangeCb?: () => void,
    ) => void;
    closeToast: () => void;
};

export const ToastContext = createContext<ToastContextType | undefined>(
    undefined,
);

interface ToastProviderProps {
    children: ReactNode;
}

interface ToastProps extends IToastProps {
    message: ReactNode;
    onChangeCb: () => void;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
    const [toast, setToast] = useState<ToastProps>({
        visible: false,
        type: "info",
        seconds: 3,
        message: "",
        onChangeCb: () => {},
    });

    const showToast = useCallback(
        (
            message: ReactNode,
            type?: "info" | "loading",
            seconds = 3,
            onChangeCb = () => {},
        ) => {
            setToast({ visible: true, message, seconds, type, onChangeCb });
        },
        [],
    );

    const handleClose = useCallback(() => {
        setToast((prev) => {
            prev.onChangeCb();
            return { ...prev, visible: false };
        });
    }, []);

    const contextValue = useMemo(
        () => ({
            showToast,
            closeToast: handleClose,
        }),
        [showToast, handleClose],
    );
    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            <FmToast
                visible={toast.visible}
                seconds={toast.seconds}
                type={toast.type}
                onChange={handleClose}
            >
                {toast.message}
            </FmToast>
        </ToastContext.Provider>
    );
};
