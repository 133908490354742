import { IGlass } from "@/src/app/basket/type";
import { BASKET_GOODS } from "@/src/hooks/useAnalysis/utils";

// type和初始值initialState
export interface Order {
    shippingTotal: string;
    subtotal: string;
    ot_vat: string;
    couponTotal: string;
    couponDetails: false;
    shippingDetails: Record<string, any>;
    paymentId: string;
    onlyPaypal: true;
    total: string;
    shippingMethodId: string;
    shippingMethod: string;
    handlingFee: string;
    comment: string;
    coupon: string;
    products: BASKET_GOODS[];
    totalNum: number;
    offTotal: number;
    onlianeTotal: number;
    offlineProducts: BASKET_GOODS[];
    showProducts: [];
    pageLoaging: boolean;
    // 默认-1 如果组件查到没有领取，那么赋值为0，如果领取了，那么赋值为时间戳
    getFreeBoxAt: number;
    // 进入详情页，先要获取镜盒信息。数量<=0不展示镜盒。用来在pc的详情页展示入口
    mirrorBoxData: any | null;
    // 免费镜盒的数量 实际库存
    qty: number;
    // 展示给用户的库存
    showQty: number;
    showEntry: boolean;
    // 当前处于哪个页面
    page: string;
    // 免费镜盒的配件id
    freeBoxAddOnId: number;
    threshold: number;
    // 嵌入式支付 用到的3个参数，以及对应参数的错误提示
    cardNumber: string;
    cardNumberErrorNotice: string;
    expirationDate: string;
    expirationDateErrorNotice: string;
    cvvOrCvc: string;
    cvvOrCvcErrorNotice: string;
}

export type State = Order;

export type Action = {
    setBascketNum: (totalNum: number) => void;
    removeProduct: (basket_id: number) => void;
    setProducts: (data: {
        onlineProducts: (IGlass & any)[];
        offlineProducts: (IGlass & any)[];
    }) => void;
    setPage: (page: string) => void;
};
export type OrderAction = State & Action;

export const initialState: State = {
    shippingTotal: "",
    subtotal: "",
    ot_vat: "",
    couponTotal: "",
    couponDetails: false,
    shippingDetails: {},
    paymentId: "",
    onlyPaypal: true,
    total: "",
    shippingMethodId: "",
    shippingMethod: "",
    handlingFee: "",
    comment: "",
    coupon: "",
    products: [],
    totalNum: 0,
    offTotal: 0,
    onlianeTotal: 0,
    offlineProducts: [],
    showProducts: [],
    pageLoaging: true,
    // 默认-1 如果组件查到没有领取，那么赋值为0，如果领取了，那么赋值为时间戳
    getFreeBoxAt: -1,
    // 进入详情页，先要获取镜盒信息。数量<=0不展示镜盒。用来在pc的详情页展示入口
    mirrorBoxData: null,
    // 免费镜盒的数量 实际库存
    qty: 0,
    // 展示给用户的库存
    showQty: 0,
    showEntry: false,
    // 当前处于哪个页面
    page: "",
    // 免费镜盒的配件id
    freeBoxAddOnId: -1,
    threshold: 200,
    // 嵌入式支付 用到的3个参数，以及对应参数的错误提示
    cardNumber: "",
    cardNumberErrorNotice: "",
    expirationDate: "",
    expirationDateErrorNotice: "",
    cvvOrCvc: "",
    cvvOrCvcErrorNotice: "",
};
