import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { initialState, Rate, Rater } from "./initialState";
import { calcCurrencies } from "@/src/utils/calcCurrencies";
import Cookies from "js-cookie";

export type RateStore = ReturnType<typeof createRateStore>;

export const createRateStore = (initProps?: Partial<Rate>) => {
    const init = Object.assign(initialState, initProps);
    return create<Rater>()(
        devtools(
            persist(
                (set, get) => ({
                    ...init,
                    changeRate: (rate) => {
                        Cookies.set("currency", rate);
                        set({ current: rate });
                    },
                    getPrice: (price, rateData) => {
                        const currentRateData = rateData ?? get().rateData;
                        const host = get().host;
                        const currentRate =
                            Cookies.get("currency") ?? get().current;
                        if (!currentRateData?.[currentRate]) {
                            return price;
                        }
                        return calcCurrencies(
                            host,
                            currentRateData,
                            currentRate,
                        )(price);
                    },
                }),
                {
                    name: "rate-storage",
                    storage: createJSONStorage(() => localStorage),
                },
            ),
            {
                name: "rate-store",
            },
        ),
    );
};
