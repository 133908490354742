/**
 *
 * 获取cpu数据
 * 精细化邮件营销，对用户浏览行为监控
 */

import { HandleJavaCommonFetch } from "../utils/fetch";
import { getVistorId } from "./customerShow";

const backendUrl = "cpu/history/user/access/add";

/**
 *
 * @param price:价格,
 * @param colorId:颜色id,
 * @param browseUrl:url,
 * @param eventType:1点击sku 2添加购物车，3添加收藏
 * @param touristId：浏览者ID
 * @param filterItem：专题页或搜索的筛选父级项目。多个时英文逗号分隔
 * @param filterSubItem：专题页或搜索的筛选子级项目。多个时英文逗号分隔
 * @param productQuantity :参数值(用于匹配对应的推广来源)
 * @param paramValue :购买的商品总数量
 * @returns
 */

//点击sku
export const addProductsSkuClickEventUser = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 1,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//添加购物车
export const addToCartClickEventUser = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 2,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//添加收藏
export const addFavouriteClickEventUser = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 3,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//点击商品item
export const addProductsToDetailEventUser = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 4,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//点击支付
export const addClickPayEventUser = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 5,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};
//支付成功
export const addPaySuccessEventUser = (param0 = {}) => {
    const visitor_id = getVistorId();
    return HandleJavaCommonFetch.post(backendUrl, {
        ...param0,
        eventType: 6,
        browseUrl: window.location.pathname,
        touristId: visitor_id,
    });
};

/**
 * paypal资源加载失败监控
 * @param {*} arg 如果paypal sdk加载失败 arg===undefined 如果是dataClientId加载失败arg==='-'
 */
export const paypalJsLoadFailEvent = (arg = "/") => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: arg,
        eventType: -10,
    });
};

// honey插件导致异常事件
export const honeyPluginEvent = () => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: -11,
    });
};

// paypal sdk加载失败之后跳转到新页面支付事件（法国站默认就是跳转到新页面支付，这种情况不会被统计）
export const openNewTabPayEvent = (monitorFlag = -12) => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: monitorFlag,
    });
};

// 进入支付页面
export const enterCheckoutEventUser = () => {
    return HandleJavaCommonFetch.post(backendUrl, {
        browseUrl: "/",
        eventType: 7,
    });
};
