/**
 *
 * 商品相关接口调用
 *
 */

import {
    PRODUCTS,
    CHECKCOMMENT,
    HOT_PRODUCTS,
    PREVIEW,
    LIKE_PRODUCTS,
    VIRTUALS,
    CLIPS,
    ADD_ONS_CONF,
    ADD_ONS,
} from "@/config/api";
import { PER_PAGE } from "@/config/constConf";
import { HandlePhpFetch, HandleJavaUsualFetch } from "@/src/utils/fetch";

export const getProductDetail = (
    id: number,
    headers?: Record<string, string>,
) => {
    const url = PRODUCTS + id;

    return HandlePhpFetch.get(url, {}, { headers });
};

export const getPreview = (params: any) => {
    return HandlePhpFetch.get(PREVIEW, params);
};

export const postflow = async (data: Record<string, any>) => {
    return HandleJavaUsualFetch.get("flow", data);
};

export const getBestSellersProducts = (id: number | string) => {
    return HandlePhpFetch.get(HOT_PRODUCTS + id);
};

export const getYouMayLikeProducts = (id: number) => {
    return HandleJavaUsualFetch.get("personalize/recommend/" + id);
};

export const getLikeProducts = (id: number) => {
    return HandlePhpFetch.get(LIKE_PRODUCTS + id);
};

export interface IProductPackageInModels {
    productsId: number;
    pageId?: number;
    pageNum?: number;
    pageSize?: number;
    headers?: any;
}
export const getProductPackage = ({
    productsId,
    pageId,
    pageNum = 1,
    pageSize = 12,
    headers,
}: IProductPackageInModels) => {
    const params = {
        productsId,
        pageId,
        pageNum,
        pageSize,
    };
    // 过滤掉没有值的属性如pageId
    const paramsExit = Object.entries(params).filter((item) => item[1]);
    const headersObj = headers && {
        headers,
    };
    return HandleJavaUsualFetch.get(
        "package/recommend",
        paramsExit,
        headersObj,
    );
};

export const checkCanComment = (id: number) => {
    return HandlePhpFetch.get(CHECKCOMMENT + id);
};

export const virtuals = (per?: number) => {
    return HandlePhpFetch.get(`${VIRTUALS}?per_page=${per || PER_PAGE}`);
};

export const getClipsData = () => {
    return HandlePhpFetch.get(CLIPS);
};

export const getAddOnsCategory = () => {
    return HandlePhpFetch.get(ADD_ONS_CONF);
};

// todo birthday2 周年庆活动的代码
interface IAddOns {
    per: number;
    mirror_box: boolean;
    category_id: number;
}
export const addOns = ({
    per = 100,
    mirror_box = false,
    category_id,
}: Partial<IAddOns>) => {
    return HandlePhpFetch.get(
        `${ADD_ONS}?pre_count=${per}&category_id=${category_id}${
            mirror_box ? "&mirror_box=1" : ""
        }`,
    );
};
