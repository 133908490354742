import { IMG_HOST } from "@/config/api";
export const IMAGE_SIZE_LARGE = "large";
export const IMAGE_SIZE_MIDDLE = "middle";
export const IMAGE_SIZE_SMALL = "small";
export const PRODUCT_DRECTION_FRONT = 2;
export const PRODUCT_DRECTION_RIGHT_45 = 1;
export const PRODUCT_DRECTION_SIDE = 3;
export const CDN = IMG_HOST();
export const PER_PAGE = 40;
export const CURRENCY_COOKIE_NAME = "currency";
export const CURRENCY_VERSION = "CURRENCY_VERSION";
export const LANGUAGE_NAME = "firmoo_lan"; //当前语言
export const SITE_NAME = "site-id";
export const PC_COOKIE_NAME = "pc";
export const JWT_COOKIE_NAME = "jwt";
export const RX_COOKIE_NAME = "rx";
export const RX_STR_COOKIE_NAME = "rxStr";
export const BUBBLE_COOKIE_NAME = "bubble";
export const ANNOUNCEMENT_COOKIE_NAME = "announcement";
export const VISITOR_COOKIE_NAME = "U1RJe61cDi4a";
export const INVITECODE_COOKIE_NAME = "invite_code";
export const UNIONCHILDCODE_COOKIE_NAME = "union_child_code";
export const MARKETING_PAGES_LIST_COOKIE_NAME = "markrting_product_list"; //二次营销页面列表
export const ATTACHMENT_COOKIE_NAME = "attachment";
export const IS_NEED_GET_VISITOR = "record_vId"; //cookie 里面的是否需要请求浏览者详情 user/visitor 标志位
export const IS_OLD_CUSTOMER_COOKIE_NAME = "is_old_customer";

export const LOCAL_STORAGE_NAMES = {
    ORDER_PRODUCTS: "order_products",
    TOURIST_ID: "tourist_id",
    COUPON_CODE: "coupon_code",
    IS_CLICK_TRACK_GUIDE: "IS_CLICK_TRACK_GUIDE", //查看物流指引记录
    ADDRESS_DEFAULT_LOCAL: "ADDRESS_DEFAULT_LOCAL", //地址缓存
    ADDRESS_BILLING_LOCAL: "ADDRESS_BILLING_LOCAL", //账单地址缓存
    PRODUCT_SLIDER_GUIDE: "PRODUCT_SLIDER_GUIDE", //商品详情页引导
    TRY_ON_WISH_LIST: "TRY_ON_WISH_LIST", //tryOn wish list
    IS_SHOW_TRY_ON_GUI_ALL: "IS_SHOW_TRY_ON_GUI_ALL",
    IS_SHOW_TRY_ON_SCREEN: "IS_SHOW_TRY_ON_SCREEN",
    IS_SHOW_TRY_ON_SHARE: "IS_SHOW_TRY_ON_SHARE",
    IS_SHOW_TRY_ON_LISTL: "IS_SHOW_TRY_ON_LISTL",
    LENS_GUIDE_TIP: "LENS_GUIDE_TIP",
    USER_RESENT_SEARCH: "USER_RESENT_SEARCH", //用户搜索记录，最多存10个
};

export const PRODUCT_TYPE = {
    GLASSES: "glasses",
    ADD_ON: "addons",
    VIRTUALS: "virtuals",
};

// export const OCEAN_PAY = {
//   account: "",
//   terminal: "",
//   development_url: "https://secure.oceanpayment.com/gateway/service/test",
//   production_url: "https://secure.oceanpayment.com/gateway/service/pay",
//   secure_code: "",
// };

export const PAYMENTS = {
    PAYPAL: "paypal",
    CREDIT_CARD: "oceanpayment",
    BILLING: "billing",
    PACYPAL: "pacypay",
    PACYPAL_SOFORT: "pacypay pacypal_sofort",
    PACYPAL_GIROGATE: "pacypay pacypal_girogate",
    USEEPAY: "useepay",
    KLARNA: "klarna",
    OXXO: "OXXO",
    MERCADOPAGO: "MercadoPago",
    SPEI: "SPEI",
};

export enum EProductTypes {
    GLASS = "glass",
    ADD_ON = "add-on",
    VIRTUAL = "virtual",
}
//记录localStorage 缓存key
export const WINDOW_LOCAL_KEYS = {
    IS_VISITED_REFERRAL_PAGE: "is_visited_referral_program_page", //老用户是否访问过 index.php?main_page=account_referralProgram 页面
    IS_CLICK_SHARE_REFERRAL_PAGE: "IS_CLICK_SHARE_REFERRAL_PAGE", //老带新页面，用户是否点击过分享按钮
};
//记录sessionStorage 缓存key
export const WINDOW_SESSION_KEYS = {
    BASKET_NUM_SESSION: "BASKET_NUM_SESSION",
    VAT_ALERT_IS_SHOW: "VAT_ALERT_IS_SHOW",
};

//记录钱海支付 错误信息
export const OCEAN_PAY_ERROR_KEYS = "OCEAN_PAY_ERROR_KEYS";
export const OCEAN_PAY_NUMBER = "OCEAN_PAY_NUMBER";
//最小库存限制
export const OUT_OF_STORE_LIMIT = 0;

//评论列表一次加载条数
export const reviewPerPage = 5;

//试戴页面产品尺寸详情图片
export const tryOnSizeDec = {
    LensWidth:
        "https://df5apg8r0m634.cloudfront.net/images/74cd6248d78b0d7355f9eeaf5bce9370.png",
    BridgeWidth:
        "https://df5apg8r0m634.cloudfront.net/images/efc4c34f105d66ee9c1edbb1856ba807.png",
    TempleLength:
        "https://df5apg8r0m634.cloudfront.net/images/baa0f2c227270900bad6b731bec9ae6c.png",
    TotalWidth:
        "https://df5apg8r0m634.cloudfront.net/images/61e4109977fb60533bd22cd05c4bc2fc.png",
    LensDiagonalSize:
        "https://df5apg8r0m634.cloudfront.net/images/e224d4fc49fa0f4d200c9a519bd3dcc0.png",
    Weight: "https://df5apg8r0m634.cloudfront.net/images/5290aecc6bc67a41704cb11121035b7a.png",
    Material:
        "https://df5apg8r0m634.cloudfront.net/images/ec42964d0060bd35e3d4b40bf1fb9b51.png",
};

export const ES_SEARCH_TYPE = {
    ALL: 0,
    PRODUCTS: 1,
    ARTICLES: 2,
    HELP: 3,
    ORDERS: 4,
};
// 周年庆活动2 领取免费镜盒时间
export const MIRROR_BOX_FREE = "MIRROR_BOX_FREE";

// 周年庆活动2 免费镜盒的配件id
export const MIRROR_BOX_FREE_ADDON = "MIRROR_BOX_FREE_ADDON";

// 这个标记用来控制是否在倒计时结束后移除镜盒。
export const ADD_ON_THIS_DEVICE = "ADD_ON_THIS_DEVICE";

export const TIME_START_NOTICED = "TIME_START_NOTICED";
// 在倒计时结束的时候给出用户提示
export const TIME_END_NOTICED = "TIME_END_NOTICED";

// 2D试戴是否第一次使用标记
export const USED_IMAGE_TRY_ON = "USED_IMAGE_TRY_ON";
// 没有登录用户上传的图片缓存
export const IMG_TRY_ON_NOT_LOGIN_INFO_CACHE =
    "IMG_TRY_ON_NOT_LOGIN_INFO_CACHE";

// 记录Fit参数信息
export const MYFIT = "MYFIT";

// 2D试戴用户PD缓存
export const USR_PD = "USR_PD";

const freeChainNotEnd = Date.now() < 1669363200000; // 2022/11/25 16:00:00
const freeConstellationMirrorClothNotEnd = false; // 2022/12/05 16:00:00
export const freeChristmasGift = Date.now() > 1670227200000; // 2022/12/05 16:00:00

const freeGift = true;
/**
 * 星座专题页id
 * 顺序 1.白羊座 2.金牛座 3.双子座 4.巨蟹座 5.狮子座 6.处女座
 *     7.天秤座 8.天蝎座 9.射手座 10.摩羯座 11.水瓶座 12.双鱼座
 */
export const zodiacPageIds = (() => {
    const arr = [];
    if (freeConstellationMirrorClothNotEnd) {
        arr.push(
            12467,
            12468,
            12469,
            12470,
            12471,
            12472,
            12473,
            12474,
            12462,
            12475,
            12476,
            12477,
        );
    }
    if (freeChainNotEnd) {
        arr.push(14001);
    }
    if (freeChristmasGift) {
        arr.push(4010);
    }
    if (freeGift) {
        arr.push(14038, 14039, 14047);
    }
    return arr;
})();
export const zodiacPages = (() => {
    const arr = [];
    if (freeConstellationMirrorClothNotEnd) {
        arr.push(
            "ARIES",
            "TAURUS",
            "GEMINI",
            "CANCER",
            "LEO",
            "VIRGO",
            "LIBRA",
            "SCORPIO",
            "SAGITTARIUS",
            "CAPRICORN",
            "AQUARIUS",
            "PISCES",
        );
    }
    if (freeChainNotEnd) {
        arr.push(arr.push("CHAINS"));
    }
    if (freeChristmasGift) {
        arr.push("FreeChristmasGift");
    }
    return arr;
})();
/**
 * @type {[key:string]:number}
 */
export const zodiacPageIdMap = zodiacPages.reduce(
    (pre: Record<string | number, any>, page, idx) => {
        pre[page] = zodiacPageIds[idx];
        return pre;
    },
    {},
);

/** 星座专题页镜布id */
export const mirrorClothIds = (() => {
    const arr = [];
    if (freeConstellationMirrorClothNotEnd) {
        arr.push(525, 533, 532, 531, 530, 529, 528, 527, 526, 524, 523, 522);
    }
    if (freeChainNotEnd) {
        arr.push(920);
    }
    if (freeChristmasGift) {
        arr.push(931);
    }
    if (freeGift) {
        arr.push(1012, 1021, 1030);
    }
    return arr;
})();

// 继续执行站内转化的弹窗
export const UPALERT_RESUME = "UPALERT_RESUME";

// 记录用户操作选择站点modal的时间
export const OPERATE_TIME = "OPERATE_TIME";

// 检测honey插件
export const CHECK_HONEY = "CHECK_HONEY";

// 获取订单信息
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";

//上一次打开网站的时间
export const LAST_TIME_THE_WEBSITE_WAS_OPENED =
    "LAST_TIME_THE_WEBSITE_WAS_OPENED";

export const UK_DRAW_START_TIME = 1669161600;
//new Date('2022-11-23 08:00:00').getTime()/1000
export const UK_DRAW_END_TIME = 1669247999;
//new Date('2022-11-24 07:59:59').getTime()/1000
export const UK_END_TIME = 1669507199;
//new Date('2022-11-27 07:59:59').getTime()/1000

export const US_DRAW_START_TIME = 1669190400;
//new Date('2022-11-23 16:00:00').getTime()/1000
export const US_DRAW_END_TIME = 1669276799;
//new Date('2022-11-24 15:59:59').getTime()/1000
export const US_DRAW_AD_TIME = 1669363200;
//new Date('2022-11-25 16:00:00').getTime()/1000
export const US_END_TIME = 1669535999;
//new Date('2022-11-27 15:59:59').getTime()/1000

export const pageType = {
    all: 0,
    index: 1,
    dynamic_page: 2,
    product: 3,
    lens: 4,
    cart: 5,
    checkout: 6,
    help_center: 7,
    user: 8,
};

export const defaultImg =
    "https://df5apg8r0m634.cloudfront.net/images/2024/0710/52fd185aa6af2160e3d393614712400c.jpg";
