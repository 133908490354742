import { HttpMethods } from "../configUtils";
import { handleErrors } from "./handleErrors";
import {
    formatHTTPGetUrl,
    getPreUrlByApiTag,
} from "./replaceUrlBydiffrentFetch";

/**
 *
 * php接口相关的http请求封装
 *
 * url：路径
 * params：参数
 *
 */
class HandlePhpFetch {
    static async request(
        url: string,
        method: HttpMethods,
        params: { [key: string]: any } = {},
        options: RequestInit = {},
    ): Promise<any> {
        let req_url: any = getPreUrlByApiTag(url, "api/remoteapi/");

        options.method = method;
        options.headers = {
            "Content-Type": "application/json",
            ...options.headers,
        };

        if (params) {
            if (method === HttpMethods.GET) {
                // https://firmoo-app-test.proxy.fmootech.cn/api/remoteapi/basket/count/?tourist_id=kt791nc6l3问号前面多一根/就会导致白跑一次请求
                req_url = formatHTTPGetUrl(req_url, params);
            } else {
                options.body = JSON.stringify(params);
            }
        }

        try {
            const response = await fetch(req_url, options);
            return handleErrors(response);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    static async get(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandlePhpFetch.request(url, HttpMethods.GET, params, options);
    }

    static async post(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandlePhpFetch.request(url, HttpMethods.POST, params, options);
    }

    static async patch(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandlePhpFetch.request(url, HttpMethods.PATCH, params, options);
    }

    static async put(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandlePhpFetch.request(url, HttpMethods.PUT, params, options);
    }

    static async delete(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandlePhpFetch.request(url, HttpMethods.DELETE, params, options);
    }
}

export default HandlePhpFetch;
