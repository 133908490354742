import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IBasket, initialState } from "./initialState";
import { getCommitBasket } from "@/src/models/basket";
import { isEmpty } from "lodash";

export const useBasketStore = create<IBasket>()(
    devtools(
        (set, get) => ({
            ...initialState,
            async getCommitBasketList() {
                set({ isLoading: true, errors: "" });
                try {
                    const {
                        glass = [],
                        virtual = [],
                        addOn = [],
                        total = {},
                    } = await getCommitBasket();
                    const onlineProducts = [...glass, ...virtual, ...addOn];

                    const totalPrice = total.price;

                    let totalNum = isEmpty(onlineProducts)
                        ? 0
                        : onlineProducts.reduce((m, n, i) =>
                              typeof m === "object"
                                  ? m.quantity + n.quantity
                                  : m + n.quantity,
                          );
                    totalNum =
                        typeof totalNum === "object"
                            ? totalNum.quantity
                            : totalNum;
                    set({
                        isLoading: false,
                        totalPrice: totalPrice,
                        totalNum: totalNum,
                        commitProducts: onlineProducts.reverse(),
                        errors: "",
                    });
                } catch (e) {
                    set({
                        isLoading: false,
                        commitProducts: [],
                        errors: JSON.stringify(e),
                    });
                }
            },
            selectBasketItems(select_basket_arr) {
                set({
                    totalPrice:
                        select_basket_arr.length === 0 ? 0 : get().totalPrice,
                    totalNum:
                        select_basket_arr.length === 0 ? 0 : get().totalNum,
                    select_basket_arr: select_basket_arr,
                });
            },
        }),
        {
            name: "basket-store",
        },
    ),
);
