import { getLocalItem, setLocalItem } from "@/lib/commonService";
import { getVistorId } from "../models/customerShow";
import { postflow } from "../models/products";
import { flowAfter } from "../models/loginModel";
const getUrlParams2 = (url: string): Record<string, string> => {
    let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/gi;
    let result: Record<string, string> = {};
    url.replace(pattern, ($, $1, $2) => {
        result[$1] = $2;
        return ""; // Add this line to return an empty string
    });
    return result;
};
class Flow {
    url = "";
    flow: null | Record<string, any> = null;
    data = "";
    init() {
        const modifiedStr = window.location.href.replace("[", "");
        const EnddStr = modifiedStr.replace("]", "");
        this.url = EnddStr;
        this.flow = getUrlParams2(this.url);
        const data = getLocalItem("flow");
        if (data) {
            this.middle(data);
        } else {
            this.first(this.flow?.flow);
        }
    }
    //第一次
    first(flow: any) {
        if (flow) {
            let param = {
                eventType: 1,
                paramValue: flow,
                touristId: getVistorId(),
            };
            flowAfter(param).then((res) => {
                setLocalItem("flow", flow);
            });
        } else {
            postflow({ url: this.url }).then((res) => {
                let data = res.data;
                if (res.data) {
                    let param = {
                        eventType: 1,
                        paramValue: data,
                        touristId: getVistorId(),
                    };
                    flowAfter(param).then((res) => {
                        setLocalItem("flow", res.data);
                    });
                }
            });
        }
    }
    //中间事件
    middle(data: any) {
        let param = {
            eventType: 3,
            paramValue: data,
            touristId: getVistorId(),
        };
        flowAfter(param).then((res) => {
            setLocalItem("flow", data);
        });
    }
    ProductClick(id: any) {
        const flow = getLocalItem("flow");
        if (flow) {
            let param = {
                eventType: 5,
                paramValue: flow,
                productsId: id,
                touristId: getVistorId(),
            };
            flowAfter(param);
        }
    }
    register(id: any) {
        const flow = getLocalItem("flow");
        if (flow) {
            let param = {
                eventType: 2,
                paramValue: flow,
                customersId: id,
                touristId: getVistorId(),
            };
            flowAfter(param);
        }
    }
    checkSuccess(res: any) {
        const flow = getLocalItem("flow");
        if (flow) {
            let param = {
                eventType: 4,
                paramValue: flow,
                touristId: getVistorId(),
                ordersOid: res.extra.orders_oid,
                price: res.extra.order_total_out_shipping,
            };
            flowAfter(param);
        }
    }
}

let addFlow = new Flow();

export default addFlow;
