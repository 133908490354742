import { HttpMethods } from "../configUtils";
import {
    formatHTTPGetUrl,
    getPreUrlByApiTag,
} from "./replaceUrlBydiffrentFetch";

/**
 *
 * java接口相关的http请求封装
 *
 * url：路径
 * params：参数
 *
 */
enum ErrorCode {
    Success = 200,
    Fail = 500,
}
async function handleErrors(response: Response): Promise<any> {
    if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
    }
    const responseData = await response.json();
    if (responseData.code == ErrorCode.Fail) {
        throw new Error(responseData.msg || responseData.data.msg);
    }
    switch (responseData.error) {
        case 0:
            return responseData.data;
        case 203002:
        case 203003:
            throw new Error(responseData.data.error);
        // 各种请求问题
        default:
            throw new Error(responseData.data.message);
    }
}

class HandleJavaReviewFetch {
    static async request(
        url: string,
        method: HttpMethods,
        params: { [key: string]: any } = {},
        options: RequestInit = {},
    ): Promise<any> {
        let req_url = getPreUrlByApiTag(url, "api/javaReview/");
        options.method = method;
        options.headers = {
            "Content-Type": "application/json",
            ...options.headers,
        };

        if (method === HttpMethods.GET) {
            req_url = formatHTTPGetUrl(req_url, params);
        } else {
            options.body = JSON.stringify(params);
        }

        try {
            const response = await fetch(req_url, options);

            return handleErrors(response);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    static async get(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaReviewFetch.request(
            url,
            HttpMethods.GET,
            params,
            options,
        );
    }

    static async post(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaReviewFetch.request(
            url,
            HttpMethods.POST,
            params,
            options,
        );
    }

    static async patch(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaReviewFetch.request(
            url,
            HttpMethods.PATCH,
            params,
            options,
        );
    }

    static async put(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaReviewFetch.request(
            url,
            HttpMethods.PUT,
            params,
            options,
        );
    }

    static async delete(
        url: string,
        params?: { [key: string]: any },
        options?: RequestInit,
    ): Promise<any> {
        return HandleJavaReviewFetch.request(
            url,
            HttpMethods.DELETE,
            params,
            options,
        );
    }
}

export default HandleJavaReviewFetch;
