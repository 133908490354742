// type和初始值initialState

export interface RateDataProps {
    code: string;
    title: string;
    symbol_left: string;
    symbol_right: string;
    value: number;
    thousands_point: string;
    decimal_point: string;
    decimal_places: string;
    is_deleted: boolean;
}

export interface Rate {
    rateData: Record<string, RateDataProps>;
    current: string;
    host: string;
}

export type State = Rate;

export type Action = {
    // 改变汇率
    changeRate: (rate: string) => void;
    getPrice: (
        price: string | number,
        // host: string,
        rateData?: Record<string, RateDataProps>,
    ) => string;
};
export type Rater = State & Action;

export const initialState: State = {
    rateData: {},
    current: "",
    host: "",
};
