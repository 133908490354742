"use client";
// import {
//     getSite,
//     getSiteConfiguration,
//     getSiteId,
// } from "@/src/utils/configUtils";
import { createContext, useRef } from "react";

// 定义数据类型
interface BasicCommonDataProps {
    host: string;
    site: string;
    siteId: number;
    is_cms: boolean;
    lang: string;
}

export interface CommonDataProps extends BasicCommonDataProps {
    site: string;
    siteId: number;
    is_cms: boolean;
}

// 创建上下文
export const CommonContext = createContext<CommonDataProps | null>(null);

// Provider组件的传参
interface CommonProviderProps
    extends React.PropsWithChildren<BasicCommonDataProps> {}

// 创建Provider组件
export function CommonProvider({ children, ...props }: CommonProviderProps) {
    const storeRef = useRef<CommonDataProps>();
    if (!storeRef.current) {
        storeRef.current = {
            ...props,
        };
    }

    return (
        <CommonContext.Provider value={storeRef.current}>
            {children}
        </CommonContext.Provider>
    );
}
