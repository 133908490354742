import { PAYMENTS } from "@/config/constConf";
import { getSite } from "@/src/utils/configUtils";
import { IInsertGetPrescription } from "@/store/modules/prescription";

export interface IClips {
    addons_id: number;
    basket_id: number;
    goods: string;
    image: string;
    is_selling: boolean;
    is_under_stock: boolean;
    mark: boolean;
    name: string;
    original_price: string;
    price: number;
    quantity: number;
    stock: number;
}
export interface IProducts {
    basket_id: number;
    frame_image?: string;
    products_frame?: string;
    frame_image_total?: number;
    frame_picture?: string;
    lens_rx_string?: any;
    master_categories_id?: any;
    clips?: IClips[];
    color?: string;
    color_id?: number;
    have_clips?: number;
    color_number?: string;
    frame?: string;
    frame_origin_price?: number;
    frame_price?: number;
    glass_type?: string;
    goods?: string | "virtual" | "add-on" | "glasses" | "glass";
    has_rx?: boolean;
    image?: string;
    is_discounting?: boolean;
    is_fresh?: boolean;
    stock?: number;
    is_under_stock?: boolean;
    products_name_attribute?: string;
    lens_package?: any;
    lens_detail?: {
        name?: string;
        option_id?: string;
        option_value_id?: string;
        price?: string;
        value?: string;
    }[];
    lens_price?: number;
    lens_recommend?: {
        description?: {
            description?: string;
            price?: string;
        };
        lens?: any;
    };
    lens_rx?: Record<string, any>;
    price?: number;
    product_id?: number;
    product_id_hash?: string;
    product_type?: number;
    products_color_selling?: boolean;
    products_selling?: boolean;
    quantity?: number;
    lens_refractive?: number;
    lens_rx_edit?: Partial<IInsertGetPrescription>;
    total_price?: number;
    virtual_id?: number;
    addons_id?: number;
    id?: number;
    name?: string;
}

export interface IVirtual {
    virtual_id: number;
    total_price: number;
    quantity: number;
    price: number;
    original_price: number;
    name: string;
    is_selling: boolean;
    image: string;
    goods: "virtual";
    description: string;
    basket_share_tag: any[];
    basket_id: number;
}

export interface IAddOn {
    addons_id: number;
    basket_id: number;
    basket_share_tag: any[];
    goods: "add-on";
    image: string;
    is_selling: boolean;
    is_under_stock: boolean;
    mark: boolean;
    name: string;
    original_price: number;
    price: number;
    quantity: number;
    stock: number;
    total_price: number;
}

//虚拟商品接口
export interface VIRTUALS_GOOD {
    virtuals_description?: string;
    virtuals_description2?: string;
    virtuals_id?: number;
    virtuals_img?: string;
    virtuals_name?: string;
    virtuals_price?: string;
    virtuals_txt?: string;
}

export interface ADDONS_DATA {
    addons_id: number;
    addons_img: string;
    addons_name: string;
    addons_price: string;
    addons_price_org: string;
    addons_weight: number;
    addons_description: string;
    addons_qty: number;
}

export type BASKET_GOODS = IAddOn | IVirtual | IProducts;
/**
 * 用于在处方选择时加购时获取埋点的参数
 */
export const getGAEventParamsForLens = (data: any) => {
    const { color_id, products_id, product_price, product } = data;
    const { search, pathname } = self.location;
    //区分h5 pc
    // const firstPath = isPc ? "pc" : "h5";
    const firstPath = "h5";
    return {
        id: products_id,
        item_id: products_id,
        name: product.products_model || "lens",
        item_name: product.products_model || "lens",
        list_name: `${firstPath}_${pathname}${search}`,
        brand: "",
        category: `${firstPath}_${pathname}${search}`,
        variant: color_id,
        list_position: 1,
        quantity: 1,
        price: (product_price && product_price.price) || 0,
    };
};
/**
 * 用于在下单时获取埋点的参数
 */
export const getGAStaticPramsForOrder = (
    list: IProducts[],
    paymentId: string,
) => {
    const event_label = getPayMethod(paymentId);
    let result = {
        items: [],
        event_label,
        content_type: event_label,
    };
    let items: any = [];
    if (!list || !list.length) return result;
    items =
        Array.isArray(list) &&
        list.map((item: IProducts) => {
            const { product_id, frame, name, addons_id, quantity, price } =
                item;
            return {
                id: product_id || addons_id,
                item_id: product_id || addons_id,
                quantity,
                price,
                name: frame || name || "",
                item_name: frame || name || "",
            };
        });
    result.items = items;
    return result;
};

const getPayMethod = (paymentId: string) => {
    let result = "";
    if (paymentId === PAYMENTS.PAYPAL || paymentId === PAYMENTS.BILLING) {
        result = "paypal";
    } else if (paymentId === PAYMENTS.CREDIT_CARD) {
        result = "ocean";
    }
    return result;
};

export const getIds = (ids: any, glasses: any) => {
    if (getSite(self.location.host) !== "uk") return ids;
    if (Array.isArray(ids)) {
        ids = ids.map((productId: any) => {
            const product = glasses.find(
                (glass: any) => glass.products_id === productId,
            );
            return product && product.color_id
                ? `${productId}-${product.color_id}`
                : productId;
        });
    } else if (ids) {
        const product = glasses.find(
            (glass: any) => glass[0].products_id === ids,
        );
        ids =
            product && product[0].color_id
                ? `${ids}-${product[0].color_id}`
                : ids;
    }
    return ids;
};

export function getContents(res: any) {
    const contents: any = [];
    const ttk_contents: any = [];
    res.orders_products.forEach((element: any) => {
        ttk_contents.push({
            content_id: `${element[0].products_id}`,
            price: parseFloat(element[0].products_price),
            content_type: "product",
            quantity: element[0].products_quantity,
        });
        contents.push({
            content_id: `${element[0].products_id}`,
            price: element[0].products_price,
            quantity: element[0].products_quantity,
        });
    });
    return { contents, ttk_contents };
}

export function getGApramasForChenckBtn(list: any[]) {
    if (!list || !list.length) return [];
    return list.map((item) => {
        const {
            product_id,
            color_id,
            quantity,
            price,
            addons_id,
            name,
            frame,
            goods,
        } = item;
        return {
            id: product_id || addons_id,
            item_id: product_id || addons_id,
            name: frame || name || "",
            item_name: frame || name || "",
            price,
            quantity,
        };
    });
}

export function mapIdsAndPriceOfProducts(products: any[], price: string) {
    return { ids: products.map((item) => item.product_id), price };
}
